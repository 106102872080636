import React from 'react';
import {
  SimpleGrid,
  Box,
  Image,
  Text,
  Heading,
  Skeleton,
  Flex,
  Spinner,
  Center,
  Button,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import AddToCart from '../../util/AddToCart';

export default function Books({ books, to, type }) {
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };

  return (
    <Box>
      {!books && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      <SimpleGrid columns={[2, 2, 3, 5]}>
        {!books.length ? (
          <Heading justifySelf={'center'}>لا يوجد</Heading>
        ) : (
          books.map(book => {
            return book.available && <Box key={book.id + Math.random()}>
              <Box mb="4" cursor="pointer">
                <Flex justifyContent="center">
                  <Box>
                    <Link to={`/${to}${book.id}`}>
                      <Skeleton w="100" h="ضعفخ" isLoaded={loaded}>
                        <Image
                          loading="lazy"
                          onLoad={imageLoaded}
                          w="90%"
                          h="auto"
                          m="0 auto"
                          shadow="lg"
                          src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                        />
                      </Skeleton>
                    </Link>
                    {book.available === 0 ? (
                      <Center>
                        <Button
                          fontSize={['xs', 'xs', 'sm', 'sm', 'md']}
                          rounded="20px"
                          mt="4"
                          w="90%"
                          fontFamily="diodrum-med !important"
                        >
                          غير متاح حاليا
                        </Button>
                      </Center>
                    ) : (
                      <AddToCart book={book} books={books} type={type} />
                    )}
                  </Box>
                </Flex>
                <Box mt="4" textAlign="center">
                  <Text
                    fontFamily="diodrum-med !important"
                    fontWeight="500"
                    fontSize={['lg', 'xl']}
                  >
                    {book.title}
                  </Text>
                  <Text fontSize="md" color="gray.600">
                    {book.sub_title}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {book.author}
                  </Text>
                  {book['old_price'] !== undefined ? (
                    <Box>
                      <Text fontWeight="bold">€{book.price}</Text>
                      <del>€{book.old_price}</del>
                    </Box>
                  ) : (
                    <Text fontWeight="bold">€{book.price}</Text>
                  )}
                </Box>
              </Box>
            </Box>
})
        )}
      </SimpleGrid>
    </Box>
  );
}
