import React from 'react';
import { hydrate, render } from 'react-dom';
// import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import store from './redux/store';
import App from './App';
import { ChakraProvider } from '@chakra-ui/core';
import theme from './util/theme';

const stripePromise = loadStripe(
  'pk_live_51HRimJHac5YbTFs9ntebbFM9vFTkpnOOZJvP7KA3wyVo9VMDNUEyR3TnEkyQ3hx5E6ivhieEVxWVRVDffwQDKjeS00R1Jx8IWI'
);

const persistor = persistStore(store);

// ReactDOM.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <React.StrictMode>
//         <Router>
//           <Elements stripe={stripePromise}>
//             <App />
//           </Elements>
//         </Router>
//       </React.StrictMode>
//     </PersistGate>
//   </Provider>,
//   document.getElementById('root')
// );

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <Router>
              <Elements stripe={stripePromise}>
                <App />
              </Elements>
            </Router>
          </React.StrictMode>
        </PersistGate>
      </ChakraProvider>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <Router>
              <Elements stripe={stripePromise}>
                <App />
              </Elements>
            </Router>
          </React.StrictMode>
        </PersistGate>
      </ChakraProvider>
    </Provider>,
    rootElement
  );
}
