import React from 'react';
import { Box, Spinner, Badge } from '@chakra-ui/core';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { getOrders } from '../redux/actions/ordersActions';

function Orders({ getOrders }) {
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    async function getData() {
      const res = await getOrders();
      console.log(res);
      if (res.status === 200) {
        setData(res.data);
      } else {
        window.location.reload();
      }
    }
    getData();
  }, [getOrders]);
  return (
    <Box
      fontFamily="diodrum-med !important"
      pr="10%"
      pl="10%"
      mt="100px"
      mb="500px"
    >
      <Helmet>
        <title>مشترياتي</title>
      </Helmet>
      {!data ? (
        <Box mb="500px" textAlign="center">
          <Spinner size="xl" />
        </Box>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>العنوان</Th>
              <Th>القيمة</Th>
              <Th>طريقة الدفع</Th>
              <Th>حالة الطلب</Th>
              <Th>مدفوع</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.order.map(order => (
              <Tr key={order.id}>
                <Td>{order.address}</Td>
                <Td> € {order.amount / 100}</Td>
                <Td>{order.method}</Td>
                <Td>{order.order_status}</Td>
                <Td>
                  {order.is_paid === 1 ? (
                    <Badge colorScheme="green">مدفوعة</Badge>
                  ) : (
                    <Badge colorScheme="red">غير مدفوعة</Badge>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getOrders: () => dispatch(getOrders()) };
};

export default connect(null, mapDispatchToProps)(Orders);
