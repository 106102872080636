import React, { useState, useEffect } from 'react';
import {
  Box,
  Spinner,
  Flex,
  Heading,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  useDisclosure,
  useColorMode,
  Text,
  useBreakpointValue,
} from '@chakra-ui/core';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation, Link } from 'react-router-dom';

import {
  getPublisherBooks,
  getPublisherCategories,
  getShopBooks,
} from '../../redux/actions/shopBooksActions';
import Filter from '../../components/shopBooks/filter';
import ShopBooks from '../../components/shopBooks/books';
import { Helmet } from 'react-helmet';
import byPublisherCat from '../../components/books/byPublisherCat';
import { FaFilter } from 'react-icons/all';
import usePersistPagination from '../../hooks/usePersistPagination';
import Headroom from 'react-headroom';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Books({ getPublisherBooks, getShopBooks, getPublisherCategories }) {
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const bgIcon = { light: '#000', dark: '#fff' };
  const color = { light: 'white', dark: 'black' };

  const [data, setData] = useState(null);
  const [dataCat, setDataCat] = useState(null);
  const [page, setPage] = useState(1);
  const [publisherName, setpublisherName] = useState(1);
  const [currentPage, setCurrentPage] = usePersistPagination();

  let query = useQuery();
  let translate = query.get('translate');
  let publisher = query.get('id');
  let category = query.get('category');

  const activeLink = {
    background: '#000',
    color: 'white',
  };

  useEffect(() => {
    async function getData() {
      const res = await getPublisherBooks({
        page,
        translate,
        publisher,
        category,
      });
      const resCat = await getPublisherCategories({ publisher });

      if (res) {
        setData(res.data);
      }
      if (resCat) {
        console.log('book Catefories pub');
        console.log(res.data.data[0].publisher.name);
        setpublisherName(res.data.data[0].publisher.name);
        setDataCat(resCat.data.book_categories);
      }
    }
    getData();
  }, [page, translate, getPublisherBooks, getShopBooks, publisher, category]);

  const handlePageClick = async data => {
    setPage(data.selected + 1);
    setCurrentPage(data.selected);
    const pageN = page;
    await localStorage.setItem('currentPublisherPage', pageN);
    console.log(
      'current Page is ',
      localStorage.getItem('currentPublisherPage')
    );
    scroll.scrollToTop();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const btnRef = React.useRef();
  return (
    <Box mt={isSmallScreen ? '0' : '70px'} mb="100px">
      <Helmet>
        <title> كشاف الكتب</title>
      </Helmet>
      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      {data && (
        <>
          {isSmallScreen && (
            <Headroom
              className="book-filter"
              style={{
                top: '70px',
                left: '0px',
                right: '0px',
                zIndex: '1',
                transform: 'translate3D(0px, 0px, 0px)',
                transition: 'all .5s ease-in-out 0s',
              }}
            >
              <Filter
                categories={data.bookCategories}
                publishers={data.publishers}
              />
            </Headroom>
          )}
          {!isSmallScreen && (
            <Filter
              categories={data.bookCategories}
              publishers={data.publishers}
            />
          )}

          <Box m={['0', '5%']} mt="1em">
            <Heading>{publisherName} </Heading>
            <Box d="flex" m="5">
              <Button
                leftIcon={<FaFilter />}
                ref={btnRef}
                bg={bgIcon[colorMode]}
                color={color[colorMode]}
                onClick={onOpen}
              >
                <Text mr={1}>اصناف الدار</Text>
              </Button>
              <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent bg={bgIcon[colorMode]} color={color[colorMode]}>
                  <DrawerCloseButton />
                  <DrawerHeader>
                    <h1>الأصناف</h1>
                  </DrawerHeader>

                  <DrawerBody>
                    {dataCat &&
                      dataCat.map(cat => (
                        <Link
                          to={`/bypublisher?id=${publisher}&category=${cat.key}&translate=${translate}`}
                        >
                          <Heading fontSize={'lg'} key={cat.id}>
                            {cat.name}
                          </Heading>
                        </Link>
                      ))}
                  </DrawerBody>

                  <DrawerFooter />
                </DrawerContent>
              </Drawer>

              <Link to={`/bypublisher?id=${publisher}&translate=0`}>
                <Heading
                  fontFamily="diodrum-med !important"
                  fontWeight="normal"
                  size="md"
                  pt="2"
                  px="4"
                  pb="4"
                  style={translate === '0' ? activeLink : {}}
                >
                  عربي
                </Heading>
              </Link>
              <Link to={`/bypublisher?id=${publisher}&translate=1`}>
                <Heading
                  fontFamily="diodrum-med !important"
                  fontWeight="normal"
                  size="md"
                  pt="2"
                  px="4"
                  pb="4"
                  style={translate === '1' ? activeLink : {}}
                >
                  مترجم
                </Heading>
              </Link>
            </Box>
            <ShopBooks books={data.data} to="book/" />
          </Box>

          <Flex>
            <ReactPaginate
              previousLabel={'السابق'}
              nextLabel={'التالى'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={data.last_page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage}
            />
          </Flex>
        </>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getPublisherBooks: payload => dispatch(getPublisherBooks(payload)),
    getShopBooks: payload => dispatch(getShopBooks(payload)),
    getPublisherCategories: payload =>
      dispatch(getPublisherCategories(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Books);
