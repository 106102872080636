import React from 'react';
import { Box, Flex, Heading, SimpleGrid, Text, Image } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import {
  FaCcMastercard,
  FaCcVisa,
  FaCcPaypal,
  FaCcApplePay,
  FaFacebook,
  FaTwitter,
  FaInstagramSquare,
  FaWhatsappSquare,
} from 'react-icons/fa';

import account from '../../images/account.png';
import signup from '../../images/signup.png';

export default function Footer() {
  return (
    <Box
      as="footer"
      py="2em"
      px={{ base: '2em', md: '10%' }}
      pb="60px"
      color="white"
      bg="black"
    >
      <SimpleGrid mb="100px" columns={[1, 1, 3, 3]} spacing="5">
        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md" mb={2}>
            Mutabموتاب
          </Heading>

          <Link to="/about">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              عن Mutabموتاب{' '}
            </Text>
          </Link>
          <Link to="/contact">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              اتصل بنا
            </Text>
          </Link>

          <Link to="/terms">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              الأحكام والشروط
            </Text>
          </Link>
          <Link to="/policy">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              سياسة الخصوصية{' '}
            </Text>
          </Link>
        </Flex>

        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md">
            وسائل التواصل الاجتماعي
          </Heading>
          <Flex mt="4">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/Mutab77417321"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaTwitter></FaTwitter>
              </Text>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/Mutab-%D9%85%D9%88%D8%AA%D8%A7%D8%A8-100298032209373/"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaFacebook></FaFacebook>
              </Text>
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mutab.it/"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaInstagramSquare></FaInstagramSquare>
              </Text>
            </a>

            {/*<a*/}
            {/*  target="_blank"*/}
            {/*  rel="noreferrer"*/}
            {/*  href="https://www.youtube.com/channel/UCSBUh4FMxnG9Wmjio_tJk1w"*/}
            {/*>*/}
            {/*  <Text*/}
            {/*    color="gray.200"*/}
            {/*    _hover={{ textDecoration: 'underline' }}*/}
            {/*    fontFamily="diodrum-med !important"*/}
            {/*    fontSize="28px"*/}
            {/*    m="2"*/}
            {/*  >*/}
            {/*    <FaYoutube></FaYoutube>*/}
            {/*  </Text>*/}
            {/*</a>*/}

            <a
              target="_blank"
              rel="noreferrer"
              href=" https://wa.me/393477104442"
            >
              <Text
                color="gray.200"
                _hover={{ textDecoration: 'underline' }}
                fontFamily="diodrum-med !important"
                fontSize="28px"
                m="2"
              >
                <FaWhatsappSquare></FaWhatsappSquare>
              </Text>
            </a>
          </Flex>
        </Flex>
        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md" mb={2}>
            الحساب
          </Heading>
          <Link to="/login">
            <Image my="2" src={account} w={{ base: '140px', md: 'auto' }} />
          </Link>

          <Link to="/register">
            <Image my="2" src={signup} w={{ base: '140px', md: 'auto' }} />
          </Link>
        </Flex>
        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md" mb={2}>
            الشحن والتوفير
          </Heading>

          <Link to="/shipping">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              الشحن والتسليم
            </Text>
          </Link>
          <Link to="/delivery">
            <Text
              color="gray.200"
              _hover={{ textDecoration: 'underline' }}
              fontFamily="diodrum-med !important"
              fontSize="md"
            >
              نقاط التسليم PuntoDiRitiro
            </Text>
          </Link>
        </Flex>

        <Flex direction="column">
          <Heading fontFamily="diodrum-bold !important" size="md" mb={2}>
            طرق الدفع{' '}
          </Heading>
          <Flex fontSize="xl">
            <Box fontSize="48px" m="2" mr="0">
              <FaCcMastercard></FaCcMastercard>
            </Box>
            <Box fontSize="48px" m="2">
              <FaCcVisa></FaCcVisa>
            </Box>
            <Box fontSize="48px" m="2">
              <FaCcPaypal></FaCcPaypal>
            </Box>
            <Box fontSize="48px" m="2">
              <FaCcApplePay></FaCcApplePay>
            </Box>
          </Flex>
        </Flex>
        {/* <Flex direction="column">
          <Text color="gray.200" fontSize="md">
            Mutab is the online store of Almutawassit Books. P.Iva 10078540969.
            Numero REA MI-2503229
          </Text>
        </Flex> */}
      </SimpleGrid>
      <Flex justifyContent="center">
        <Box>
          {' '}
          Mutab is the online store of Almutawassit Books. P.Iva 10078540969.
          Numero REA MI-2503229 / © 2021 Almutawassit Books
        </Box>
      </Flex>
    </Box>
  );
}
