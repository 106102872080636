import * as t from '../types';
import axios from 'axios';
import { countiresKeys } from '../../util/countries';

export const checkShipping = () => async (dispatch, getState) => {
  const auth = getState().auth;
  const cart = getState().cart;

  let qnt = 0;
  /** REVIEW See if it works */
  const books = [...cart.books, ...cart.italianBooks];

  for (let i = 0; i < books.length; i++) {
    qnt += books[i].qnt;
  }

  console.log('Quantity', qnt);
  console.log('cart ', cart.books);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  await axios
    .get(`${process.env.REACT_APP_API}/profile`, { headers })
    .then(res => {
      console.log(res);

      console.log('BOOKS', books);

      /** REVIEW See if it works */
      if (books.some(bk => bk.book.free_shipping === 1)) {
        dispatch({ type: t.CHECK_SHIPPING, payload: 0 });
      } else if (countiresKeys.includes(res.data.country)) {
        console.log('italy');
        if (res.data.country === 'IT') {
          console.log('italy activated');
          dispatch({ type: t.CHECK_SHIPPING, payload: 0 });
        } else if (qnt >= 4) {
          dispatch({ type: t.CHECK_SHIPPING, payload: 0 });
        } else {
          dispatch({ type: t.CHECK_SHIPPING, payload: 10 });
        }
      } else {
        console.log('iraq and lebanon');
        if (res.data.country === 'LB' || res.data.country === 'IQ') {
          console.log('iraq and lebanon activated');
          dispatch({ type: t.CHECK_SHIPPING, payload: 0 });
        } else if (qnt > 20) {
          dispatch({ type: t.CHECK_SHIPPING, payload: 0 });
        } else {
          dispatch({ type: t.CHECK_SHIPPING, payload: 10 });
        }
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const addBook = book => async (dispatch, getState) => {
  dispatch({ type: t.ADD_BOOK, payload: book });
};

export const addItalianBook = book => async (dispatch, getState) => {
  dispatch({ type: t.ADD_ITALIAN_BOOK, payload: book });
};

export const removeBook = book => async (dispatch, getState) => {
  console.log('removing book');
  dispatch({ type: t.REMOVE_BOOK, payload: book });
};

export const removeItalianBook = book => async (dispatch, getState) => {
  console.log('removing book');
  dispatch({ type: t.REMOVE_ITALIAN_BOOK, payload: book });
};

export const qntUp = item => async (dispatch, getState) => {
  dispatch({ type: t.QNT_UP, payload: item });
};

export const qntUpIt = item => async (dispatch, getState) => {
  dispatch({ type: t.QNT_UP_IT, payload: item });
};

export const qntDown = item => async (dispatch, getState) => {
  dispatch({ type: t.QNT_DOWN, payload: item });
};

export const qntDownIt = item => async (dispatch, getState) => {
  dispatch({ type: t.QNT_DOWN_IT, payload: item });
};

export const clearCart = item => async (dispatch, getState) => {
  dispatch({ type: t.CLEAR_CART });
};

function isNotEmptyArray(arr) {
  return Array.isArray(arr) && arr.length !== 0;
}
