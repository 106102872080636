import axios from 'axios';

export const emailResend = page => async (dispatch, getState) => {
  console.log('email resend triggered');
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .get(`${process.env.REACT_APP_API}/auth/signup/resend`, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err.response);
    });
  return data;
};

export const verifyEmail = token => async (dispatch, getState) => {
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .get(
      `${process.env.REACT_APP_API}/auth/signup/activate/${token}`,

      { headers }
    )
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err.response);
      return err.response;
    });
  return data;
};
