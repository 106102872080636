export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SAVE = 'AUTH_SAVE';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR';
export const ADD_BOOK = 'ADD_BOOK';
export const ADD_ITALIAN_BOOK = 'ADD_ITALIAN_BOOK';
export const REMOVE_BOOK = 'REMOVE_BOOK';
export const REMOVE_ITALIAN_BOOK = 'REMOVE_ITALIAN_BOOK';
export const QNT_UP = 'QNT_UP';
export const QNT_UP_IT = 'QNT_UP_IT';
export const ADD_IP = 'ADD_IP';
export const QNT_DOWN = 'QNT_DOWN';
export const QNT_DOWN_IT = 'QNT_DOWN_IT';
export const CLEAR_CART = 'CLEAR_CART';
export const CHECK_SHIPPING = 'CHECK_SHIPPING';
