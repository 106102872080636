import React from 'react';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';
import Carousel from 'react-elastic-carousel';

import {
  Heading,
  Box,
  Image,
  Spinner,
  Text,
  useColorMode,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
// import fx from 'money';
import { connect } from 'react-redux';
import { getHome } from '../redux/actions/homeActions';

function Home({ getHome }) {
  const { colorMode } = useColorMode();

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const color = { light: 'black', dark: 'white' };
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  React.useEffect(() => {
    async function getData() {
      const res = await getHome();
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, []);

  const breakpointColumnsObj = {
    default: 3,
    1300: 3,
    1100: 2,
    1000: 1,
  };
  const breakpointColumns = {
    default: 4,
    1300: 4,
    1100: 3,
    1000: 2,
    700: 1,
  };
  const bookBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
  ];
  console.log(process.env.REACT_APP_API);

  return (
    <Box px={{ base: '1%', md: '10%' }} my={{ base: '2em', md: '100px' }}>
      <Helmet>
        <title>متجر موتاب</title>
      </Helmet>
      {!data ? (
        <Box mb="500px" textAlign="center">
          <Spinner size="xl" />
        </Box>
      ) : (
        <>
          <Text mb="2">{data && data.image.name}</Text>
          <Image
            loading="lazy"
            w="100%"
            loading="lazy"
            src={`${process.env.REACT_APP_STORAGE}/${data && data.image.image}`}
          />
          {data && data.books[0] && (
            <Box
              //   pr="5%"
              //   pl="3%"
              bg={bg[colorMode]}
              borderBottom="1px solid white"
            >
              <Box mt="100px" mb="4" color={color[colorMode]}>
                <Heading
                  fontFamily="diodrum-med !important"
                  mr="6%"
                  p="4"
                  size="lg"
                >
                  الكتب الاكثر مبيعا
                </Heading>
              </Box>
              <Carousel
                breakPoints={bookBreakPoints}
                isRTL={true}
                style={{
                  //   marginTop: 100,

                  padding: 10,
                }}
                // itemsToScroll={3}
                // itemsToShow={3}
              >
                {data &&
                  data.bestSeller &&
                  data.bestSeller.map(book => (
                    <Link key={book.id} to={`/book/${book.id}`}>
                      <Box w={['100', '350px']} mb="4" pb="4" cursor="pointer">
                        <Image
                          loading="lazy"
                          w={['100', '225px']}
                          h={['auto', '350px']}
                          m="0 auto"
                          shadow="lg"
                          src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                        ></Image>
                        <Box mt="4" textAlign="center">
                          <Text
                            fontFamily="diodrum-med !important"
                            fontWeight="500"
                            fontSize={['md', 'xl']}
                            mb="8"
                          >
                            {book.title}
                          </Text>
                          {/* <Text fontSize="md">{book.sub_title}</Text>
                        <Text fontSize="sm">{book.author}</Text>
                        <Text fontWeight="bold">${book.price}</Text> */}
                        </Box>
                      </Box>
                    </Link>
                  ))}
              </Carousel>
            </Box>
          )}
          {/* <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data &&
          data.articles &&
          data.articles.map(article => (
            <Link key={article.id} to={`/singlePost?id=${article.id}`}>
              <Box
                bg="white"
                w="100%"
                shadow="lg"
                // p="2"
                pb="4"
                // m="4"
                mt="8"
                cursor="pointer"
              >
                <Skeleton w="100%" isLoaded={loaded}>
                  <Image
                    w="100%"
                    onLoad={imageLoaded}
                    src={`${process.env.REACT_APP_STORAGE}/${article.image}`}
                  ></Image>
                </Skeleton>
                <Heading m="4"> {article.title} </Heading>
                <Box
                  m="4"
                  fontSize="xl"
                  className="content event-body"
                  dangerouslySetInnerHTML={{ __html: article.body }}
                ></Box>
              </Box>
            </Link>
          ))}
      </Masonry> */}
          <Heading fontFamily="diodrum-med !important" mt="4" size="lg">
            كتب اخترناها لكم
          </Heading>

          <Masonry
            breakpointCols={breakpointColumns}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {data &&
              data.books &&
              data.books.map(book => (
                <Link to={`/book/${book.id}`}>
                  <Box mt="4" pb="4" shadow="lg" bg={bg[colorMode]}>
                    <Image
                      loading="lazy"
                      mt="2"
                      w="100%"
                      src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                    ></Image>

                    <Text
                      fontFamily="diodrum-med !important"
                      fontSize="2xl"
                      m="2"
                    >
                      {' '}
                      {book?.author[0]?.name}{' '}
                    </Text>

                    <Heading fontFamily="diodrum-bold !important" m="4">
                      {' '}
                      {book.title}{' '}
                    </Heading>
                    <Box
                      m="4"
                      fontSize="xl"
                      className="content books__content"
                      dangerouslySetInnerHTML={{ __html: book.overview }}
                    ></Box>
                  </Box>
                </Link>
              ))}
          </Masonry>
        </>
      )}
    </Box>
  );
}
const mapDispatchToProps = dispatch => {
  return { getHome: () => dispatch(getHome()) };
};

export default connect(null, mapDispatchToProps)(Home);
