import React from 'react';
import { Box, Flex, IconButton, useToast } from '@chakra-ui/core';
import { connect } from 'react-redux';
import {
  qntUp,
  qntDown,
  qntUpIt,
  qntDownIt,
} from '../../redux/actions/cartActions';
import { FaMinus, FaPlus } from 'react-icons/fa';

function Quantity({ qntDown, qntUp, qntUpIt, qntDownIt, item, qnt, type }) {
  const toast = useToast();

  return (
    <Flex align="center">
      <IconButton
        icon={<FaMinus />}
        onClick={() => {
          if (type === 'it') {
            qntDownIt(item);
          } else {
            qntDown(item);
          }
        }}
        disabled={qnt === 1}
        variant="outline"
        colorScheme="green"
      />
      <Box fontSize="1.6rem" mx=".5em">
        {qnt}
      </Box>
      <IconButton
        icon={<FaPlus />}
        onClick={() => {
          // NOTE QUANTITY LIMIT TOAST
          // if (item.qnt === item.book.qty - 1) {
          //   toast({
          //     description: 'لقد بلغت الحد المتوفر في المخزون',
          //     position: 'top-right',
          //     status: 'error',
          //     duration: 2000,
          //     isClosable: true,
          //   });
          // }

          if (type === 'it') {
            qntUpIt(item);
          } else {
            qntUp(item);
          }
        }}
        // NOTE QUANTITY LIMIT disabled={qnt >= item.book.qty}
        variant="outline"
        colorScheme="green"
      />
    </Flex>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    qntUp: item => dispatch(qntUp(item)),
    qntUpIt: item => dispatch(qntUpIt(item)),
    qntDown: item => dispatch(qntDown(item)),
    qntDownIt: item => dispatch(qntDownIt(item)),
  };
};

export default connect(null, mapDispatchToProps)(Quantity);
