import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const get25Books = query => async dispatch => {
  let data;

  data = await axios
    .get(`${process.env.REACT_APP_API}/mutabFeatured?mutab_featurd=${query}`, {
      headers,
    })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
      return { error: 'حدث خطا ما راجع البيانات واعد المحاولة' };
    });

  return data;
};
