import React, { useState, useEffect } from 'react';
import { Box, Alert, Spinner } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { verifyEmail } from '../redux/actions/verifyActions';
import Resend from '../components/Resend';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Verified({ verifyEmail }) {
  let query = useQuery();
  let token = query.get('token');
  const [data, setData] = useState();

  useEffect(() => {
    async function getData() {
      const res = await verifyEmail(token);
      setData(res);
      console.log(res);
    }
    getData();
  }, [verifyEmail, token]);

  return (
    <Box
      fontFamily="diodrum-med !important"
      textAlign="center"
      pr="10%"
      pl="10%"
      mt="100px"
      mb="500px"
    >
      {!data ? (
        <Spinner></Spinner>
      ) : (
        <>
          {data?.status === 200 ? (
            <Alert status="success">{data?.data?.message}</Alert>
          ) : (
            <>
              <Alert status="error">{data?.data?.message}</Alert>
              <Resend></Resend>
            </>
          )}
        </>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { verifyEmail: token => dispatch(verifyEmail(token)) };
};

export default connect(null, mapDispatchToProps)(Verified);
