import React, { useState, useEffect } from 'react';
import { Box, Spinner, Flex, useBreakpointValue } from '@chakra-ui/core';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation } from 'react-router-dom';

import { getCategoryBooks } from '../../redux/actions/shopBooksActions';
import Filter from '../../components/shopBooks/filter';
import CategoryBooks from '../../components/shopBooks/bycategory';
import { Helmet } from 'react-helmet';
import usePersistPagination from '../../hooks/usePersistPagination';
import Headroom from 'react-headroom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Books({ getCategoryBooks }) {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = usePersistPagination();

  let query = useQuery();
  let translate = query.get('translate');
  let category = query.get('category');

  useEffect(() => {
    async function getData() {
      const res = await getCategoryBooks({ page, translate, category });
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [page, translate, getCategoryBooks, category]);

  const handlePageClick = data => {
    setPage(data.selected + 1);
    setCurrentPage(data.selected);
    scroll.scrollToTop();
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'} mb="100px">
      <Helmet>
        <title> كشاف الكتب</title>
      </Helmet>
      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      {data && (
        <>
          {isSmallScreen && (
            <Headroom
              className="book-filter"
              style={{
                top: '70px',
                left: '0px',
                right: '0px',
                zIndex: '1',
                transform: 'translate3D(0px, 0px, 0px)',
                transition: 'all .5s ease-in-out 0s',
              }}
            >
              <Filter
                categories={data.bookCategories}
                publishers={data.publishers}
              />
            </Headroom>
          )}
          {!isSmallScreen && (
            <Filter
              categories={data.bookCategories}
              publishers={data.publishers}
            />
          )}
          <Box mx={['0', '5%']}>
            <CategoryBooks category={category} books={data.data} />
          </Box>

          <Flex>
            <ReactPaginate
              previousLabel={'السابق'}
              nextLabel={'التالى'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={data.last_page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage}
            />
          </Flex>
        </>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getCategoryBooks: payload => dispatch(getCategoryBooks(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Books);
