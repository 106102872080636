import React from 'react';
import Masonry from 'react-masonry-css';

import {
  Box,
  Flex,
  Badge,
  useColorMode,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Image,
  SimpleGrid,
  useBreakpointValue,
  Heading,
} from '@chakra-ui/core';
// import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSearch } from '../../redux/actions/searchActions';

import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Index,
  connectHits,
  connectSearchBox,
  connectStateResults,
} from 'react-instantsearch-dom';

import {
  FaGoogle,
  FaFacebookF,
  FaHamburger,
  FaShoppingCart,
  FaDollarSign,
  FaSearch,
  FaNewspaper,
  FaMoon,
  FaSun,
  FaHome,
} from 'react-icons/fa';
// import Newsletter from './NewsLetter';
import hdark from '../../images/hdark.png';
import hlight from '../../images/hlight.png';
const searchClient = algoliasearch(
  process.env.REACT_APP_algoliaAppKey,
  process.env.REACT_APP_algoliaAdminKey
);

function Navbar({ getSearch, cart }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  const { colorMode, toggleColorMode } = useColorMode();

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const bgIcon = { light: '#000', dark: '#fff' };
  const color = { light: 'white', dark: 'black' };

  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);

  //   React.useEffect(() => {
  //     async function getData() {
  //       const res = await getSearch('la');
  //       console.log(res);
  //       if (res) {
  //         setData(res.data);
  //       }
  //     }
  //     getData();
  //   }, []);

  const Books = ({ hits }) => (
    <Box>
      {hits[0] !== undefined && <Heading m="8">كتب</Heading>}

      <SimpleGrid spacing={8} columns={[1, 2, 3, 8]}>
        {hits &&
          hits.map(hit => (
            <Link onClick={onClose} key={hit.objectID} to={`/book/${hit.id}`}>
              <Image
                src={`${process.env.REACT_APP_STORAGE}/${hit.cover}`}
              ></Image>
              <Heading size="md" mt="2">
                {hit.title}
              </Heading>
            </Link>
          ))}
      </SimpleGrid>
    </Box>
  );
  const Authors = ({ hits }) => (
    <Box>
      {hits[0] !== undefined && <Heading m="8">كتاب</Heading>}
      <Masonry
        breakpointCols={8}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {hits &&
          hits.map(hit => (
            <Box mb="4">
              <Link
                onClick={onClose}
                key={hit.objectID}
                to={`/author/${hit.id}`}
              >
                <Image
                  loading="lazy"
                  src={`${process.env.REACT_APP_STORAGE}/${hit.image}`}
                ></Image>
                <Heading size="md" mt="2">
                  {hit.name}
                </Heading>
              </Link>
            </Box>
          ))}
      </Masonry>
    </Box>
  );
  const Articles = ({ hits }) => (
    <Box>
      {hits[0] !== undefined && <Heading m="8">مقالات</Heading>}

      <Masonry
        breakpointCols={8}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {hits &&
          hits.map(hit => (
            <Box mb="2" p="2" bg="white" color="black">
              <Link
                onClick={onClose}
                key={hit.objectID}
                to={`/singlePost?id=${hit.id}`}
              >
                <Image
                  loading="lazy"
                  src={`${process.env.REACT_APP_STORAGE}/${hit.image}`}
                ></Image>
                <Heading size="sm" mt="2">
                  {hit.title}
                </Heading>
              </Link>
            </Box>
          ))}
      </Masonry>
    </Box>
  );
  const CustomSearchBox = ({ currentRefinement, refine }) => (
    <Input
      className="search-box"
      color="black"
      bg="white"
      placeholder=" ابحث عن الكتب,المقالات,الكتاب ..."
      type="search"
      value={currentRefinement}
      onChange={event => refine(event.currentTarget.value)}
    ></Input>
  );

  const BooksHits = connectHits(Books);
  const BooksResults = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? <BooksHits></BooksHits> : null
  );
  const AuthorsHits = connectHits(Authors);
  const AuthorsResults = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? <AuthorsHits></AuthorsHits> : null
  );
  const ArticlesHits = connectHits(Articles);
  const ArticlesResults = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? <ArticlesHits></ArticlesHits> : null
  );
  const SearchBox = connectSearchBox(CustomSearchBox);

  const navClassName = useBreakpointValue({ base: '', md: 'topNavbar' });

  return (
    <Flex
      h="70px"
      className={navClassName}
      borderBottom="1px solid #ddd"
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="0.5rem"
      //   shadow="lg"
      color={color[colorMode]}
      bg={bg[colorMode]}
    >
      <Box
        ml="8%"
        // display={{ base: show ? 'block' : 'none', md: 'flex' }}
        d="flex"
        // width={{ base: 'full', md: 'auto' }}
        alignItems="center"
        justifyContent="flex-end"
        flexGrow={1}
      >
        <a href={process.env.REACT_APP_HOME} target="_blank" rel="noreferrer">
          <Box
            cursor="pointer"
            d="flex"
            rounded="5px"
            // bg={bgIcon[colorMode]}
            color={color[colorMode]}
            m="3px"
            // p="10px"
            w="50px"
            h="50px"
            fontSize="30px"
            position="relative"
            fontWeight="bold"
            justifyContent="center"
          >
            {colorMode === 'light' ? (
              <Image rounded="5px" w="50px" h="50px" src={hdark}></Image>
            ) : (
              <Image rounded="5px" w="50px" h="50px" src={hlight}></Image>
            )}
          </Box>
        </a>
        <Box fontSize="18px">
          {/* <FaSearch onClick={onOpen}></FaSearch> */}
          <Box
            rounded="5px"
            onClick={onOpen}
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            w="50px"
            h="50px"
            m="3px"
            p="10px"
            fontSize="28px"
          >
            <FaSearch></FaSearch>
          </Box>
          <Drawer
            placement="bottom"
            onClose={onClose}
            isOpen={isOpen}
            size="full"
          >
            <DrawerOverlay>
              <DrawerContent pt={['100px', '0']} bg="black" color="white">
                <DrawerCloseButton
                  position="absolute"
                  top="10px"
                  left="10px"
                  right="none"
                  mt={['100px', '0']}
                />

                <DrawerHeader fontSize="36px">بحث</DrawerHeader>
                <DrawerBody>
                  {/* <Input
                    color="black"
                    placeholder=" ابحث عن الكتب,المقالات,الكتاب ..."
                  ></Input>
                  <Box h="400px">
                    <Text>هنا ستكون نتيجة البحث</Text>
                  </Box> */}
                  <InstantSearch indexName="books" searchClient={searchClient}>
                    <SearchBox />
                    <Index indexName="books">
                      {/* <BooksHits></BooksHits> */}
                      <BooksResults></BooksResults>
                    </Index>

                    <Index indexName="articles">
                      <ArticlesResults />
                    </Index>
                    <Index indexName="authors">
                      <AuthorsResults />
                    </Index>
                  </InstantSearch>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </Box>
        <Link to="/cart">
          <Box
            d="flex"
            rounded="5px"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            w="50px"
            h="50px"
            m="3px"
            p="10px"
            fontSize="28px"
            position="relative"
          >
            {cart && cart.cartItemNumber !== 0 && (
              <Badge
                className="pulse-badge"
                position="absolute"
                left="-4"
                top="-2"
                textAlign="center"
                w="27px"
                h="27px"
                rounded="50%"
                fontSize="20px"
                ml="2"
                bg="red.500"
                color="white"
              >
                {cart && cart.cartItemNumber}
              </Badge>
            )}
            <FaShoppingCart></FaShoppingCart>
          </Box>
        </Link>
      </Box>
    </Flex>
  );
}

const mapDispatchToProps = dispatch => {
  return { getSearch: id => dispatch(getSearch(id)) };
};
const mapStateToProps = state => {
  return { cart: state.cart };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
