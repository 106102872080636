import React from 'react';
// import { Link } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/core';

export default function meduimBooks() {
  return (
    <Box mt="100px" mb="500px" p="30px" textAlign="center">
      <Heading>صفحة قيد التطوير</Heading>
    </Box>
  );
}
