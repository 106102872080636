import React from 'react';
import {
  SimpleGrid,
  Box,
  Image,
  Text,
  Skeleton,
  Flex,
  Spinner,
  Heading,
} from '@chakra-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import { get25Books } from '../../redux/actions/25booksActions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CatBooks({ get25Books }) {
  let query = useQuery();
  let mutab_featurd = query.get('mutab_featurd');
  console.log(mutab_featurd);

  const [loaded, setLoaded] = React.useState(false);
  const [title, setTitle] = React.useState();

  const imageLoaded = () => {
    setLoaded(true);
  };
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
    async function getData() {
      const res = await get25Books(mutab_featurd);
      if (mutab_featurd === '25before') {
        setTitle('كتب يجب أن تقرأها قبل ان تموت');
      } else if (mutab_featurd === '25start') {
        setTitle('كتب لتبدأ بها القراءة');
      } else if (mutab_featurd === '25chosen') {
        setTitle('كُتّاب اختاروا لك');
      }
      console.log(res);
      if (res.status === 200) {
        setData(res);
      }
    }
    getData();
  }, [mutab_featurd, get25Books]);

  return (
    <Box
      mr={['0', '80px']}
      ml={['0', '80px']}
      mt={{ base: '2em', md: '8em' }}
      mb="100px"
    >
      <Helmet>
        <title>{title && title}</title>
      </Helmet>
      <Box>
        {!data && (
          <Box textAlign="center">
            <Spinner size="xl" />
          </Box>
        )}
        <Heading mr="50px" size="lg" mb="8" fontFamily="diodrum-med !important">
          {' '}
          {title && title}
        </Heading>
        <SimpleGrid columns={[2, 2, 3, 5]}>
          {data?.data?.books?.map(book => (
            <Link key={book.id} to={`/book/${book.id}`}>
              <Box mb="4" cursor="pointer">
                <Flex justifyContent="center">
                  <Box>
                    <Skeleton w="100" isLoaded={loaded}>
                      <Image
                        loading="lazy"
                        onLoad={imageLoaded}
                        w="90%"
                        h="auto"
                        m="0 auto"
                        shadow="lg"
                        src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                      ></Image>
                    </Skeleton>
                  </Box>
                </Flex>
                <Box mt="4" textAlign="center">
                  <Text
                    fontFamily="diodrum-med !important"
                    fontWeight="500"
                    fontSize={['lg', 'xl']}
                  >
                    {book.title}
                  </Text>
                  <Text fontSize="md" color="gray.600">
                    {book.sub_title}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {book?.author[0]?.name}
                  </Text>
                  <Text fontWeight="bold">
                    {book.price && `€${book.price}`}
                  </Text>
                </Box>
              </Box>
            </Link>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}

const mapStateToProps = state => {
  return { cart: state.cart, auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    get25Books: mutab_featurd => dispatch(get25Books(mutab_featurd)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatBooks);
