import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getShopBooks = payload => async (dispatch, getState) => {
  console.log(payload);
  console.log('GET STATE', getState());
  const ip = getState().ip.ip;
  axios.defaults.headers.common['CLIENT_IP'] = ip;
  const { page, translate } = payload;
  const data = await axios
    .get(`${process.env.REACT_APP_API}/loadStore?page=${page}`, { headers })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};

/** REVIEW See if it works */
export const getItalianShopBooks = payload => async (dispatch, getState) => {
  console.log(payload);
  console.log('GET STATE', getState());
  const ip = getState().ip.ip;
  axios.defaults.headers.common['CLIENT_IP'] = ip;
  const { page } = payload;
  const data = await axios
    .get(`${process.env.REACT_APP_API}/italianBooks?page=${page}`, { headers })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};

export const getMediumBooks = payload => async (dispatch, getState) => {
  console.log(payload);
  const { page, translate } = payload;
  if (translate) {
    const data = await axios
      .get(
        `${process.env.REACT_APP_API}/filterStore?website=منشورات المتوسط&translate=${translate}&page=${page}`,
        //   { website: 'المتوسط' },
        { headers }
      )
      .then(res => {
        //   console.log(res);
        return res;
      })
      .catch(err => {
        console.log(err.response);
      });
    return data;
  }
  const data = await axios
    .get(
      `${process.env.REACT_APP_API}/filterStore?website=منشورات المتوسط&page=${page}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      //   console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err.response);
    });
  return data;
};
export const getCategoryBooks = payload => async (dispatch, getState) => {
  console.log(payload);
  const { page, translate, category } = payload;
  if (translate) {
    const data = await axios
      .get(
        `${process.env.REACT_APP_API}/filterStore?category=${category}&translate=${translate}&page=${page}`,
        //   { website: 'المتوسط' },
        { headers }
      )
      .then(res => {
        //   console.log(res);
        return res;
      })
      .catch(err => {
        console.log(err.response);
      });
    return data;
  }
  const data = await axios
    .get(
      `${process.env.REACT_APP_API}/filterStore?category=${category}&page=${page}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      //   console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err.response);
    });
  return data;
};

export const getPublisherCategories = payload => async (dispatch, getState) => {
  console.log(payload);
  const { publisher } = payload;

  return await axios
    .get(
      `${process.env.REACT_APP_API}/loadCategoriesByPublisher/${publisher}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      //   console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err.response);
    });
};

export const getPublisherBooks = payload => async (dispatch, getState) => {
  console.log(payload);
  const { page, translate, publisher, category } = payload;
  if (translate) {
    if (category) {
      const data = await axios
        .get(
          `${process.env.REACT_APP_API}/filterStore?publisher=${publisher}&category=${category}&translate=${translate}&page=${page}`,
          //   { website: 'المتوسط' },
          { headers }
        )
        .then(res => {
          //   console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err.response);
        });
      return data;
    } else {
      const data = await axios
        .get(
          `${process.env.REACT_APP_API}/filterStore?publisher=${publisher}&&translate=${translate}&page=${page}`,
          //   { website: 'المتوسط' },
          { headers }
        )
        .then(res => {
          //   console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err.response);
        });
      return data;
    }
  }
  const data = await axios
    .get(
      `${process.env.REACT_APP_API}/filterStore?publisher=${publisher}&page=${page}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      //   console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err.response);
    });
  return data;
};
