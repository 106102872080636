import React from 'react';
import {
  CSSReset,
  Box,
  useBreakpointValue,
} from '@chakra-ui/core';
import { Switch, Route  } from 'react-router-dom';
import { connect } from 'react-redux';
import MessengerCustomerChat from 'react-messenger-customer-chat';

// import { getIp } from './redux/actions/ipAction';

//?pages
import Index from './pages/index';
import About from './pages/about';
import Author from './pages/Author';
import Verified from './pages/Verified';
import Four from './pages/404';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import CheckoutPay from './pages/CheckoutPay';
import Profile from './pages/Profile';
import Register from './pages/Register';
import Login from './pages/Login';
import ForgetPassword from './pages/forgetPassword';
import FeaturedBooks from './pages/FeaturedBooks';
import FurtherComing from './pages/FurtherComing';
import BooksByCategory from './pages/BooksByCategory';
import BooksSeries from './pages/BooksSeries';

// import NewBooks from './pages/newBooks';
// import PrizeBooks from './pages/prizeBooks';

import UsedBooks from './pages/usedBooks';
import Orders from './pages/Orders';
import Books25 from './pages/25books/Books25';

//?shop books
import Books from './pages/shopBooks/books';
import MediumBooks from './pages/shopBooks/mediumBooks';
import ByCategory from './pages/shopBooks/bycategory';
import ByPublisher from './pages/shopBooks/bypublisher';

import Book from './pages/book';
import Events from './pages/events';
import InBooksLove from './pages/inBooksLove';
import SinglePost from './pages/singlePost';

import Navbar from './components/layout/Navbar';
import TopNavbar from './components/layout/TopNavbar';
import Footer from './components/layout/Footer';

//?footer
import Contact from './pages/footer/contact';
import Terms from './pages/footer/terms';
import Policy from './pages/footer/policy';
import Shipping from './pages/footer/Shipping';
import Delivery from './pages/footer/Delivery';
//?util
import Up from './util/Up';
import SocialButtons from './util/SocialButtons';
import ScrollToTop from './util/ScrollToTop';

import Headroom from 'react-headroom';



import './styles/globals.css';
import Writers from './pages/Writers';
import Writer from './pages/Writer';

function App({ auth, getIp }) {
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  console.log(auth);
  //   React.useEffect(() => {
  //     getIp();
  //   }, []);

  return (
    <>
      <CSSReset />
      {isSmallScreen && (
        <Headroom style={{ transition: 'all .5s ease-in-out 0s', zIndex: '2' }}>
          <TopNavbar />
        </Headroom>
      )}
      {!isSmallScreen && <TopNavbar />}
      <SocialButtons />
      <ScrollToTop />
      <Box as="main" className="main">
        <Switch>
          <Route title="index" exact path="/">
            <Index />
          </Route>
          <Route title="about" exact path="/about">
            <About />
          </Route>
          <Route title="contact" exact path="/contact">
            <Contact />
          </Route>
          <Route title="terms" exact path="/terms">
            <Terms />
          </Route>
          <Route title="policy" exact path="/policy">
            <Policy />
          </Route>
          <Route title="shipping" exact path="/shipping">
            <Shipping />
          </Route>
          <Route title="delivery" exact path="/delivery">
            <Delivery />
          </Route>
          <Route title="writers" exact path="/writers">
            <Writers />
          </Route>
          <Route title="writer" exact path="/writer/:id">
            <Writer />
          </Route>
          <Route title="books" exact path="/shopbooks">
            <Books />
          </Route>
          {/** NOTE ITALIAN BOOK ROUTES */}
          {/* <Route title="books" exact path="/italian/shopbooks">
            <ItalianBooks />
          </Route>
          <Route title="book" exact path="/italian/book/:id">
            <ItalianBook />
          </Route> */}
          <Route title="books25" exact path="/25books">
            <Books25 />
          </Route>
          <Route title="mediumbooks" exact path="/mediumbooks">
            <MediumBooks />
          </Route>
          <Route title="bycategory" exact path="/bycategory">
            <ByCategory />
          </Route>
          <Route title="bypublisher" exact path="/bypublisher">
            <ByPublisher />
          </Route>
          <Route title="author" exact path="/author/:id">
            <Author />
          </Route>
          <Route title="checkout" exact path="/checkout">
            {auth && auth.data ? <Checkout /> : <Login></Login>}
          </Route>
          <Route title="checkout_pay" exact path="/checkout_pay">
            {auth && auth.data ? <CheckoutPay /> : <Login></Login>}
          </Route>
          <Route title="register" exact path="/register">
            {auth && auth.data ? <Index></Index> : <Register />}
          </Route>
          <Route title="forgetpassword" exact path="/forgetPassword">
            {auth && auth.data ? <Index></Index> : <ForgetPassword />}
          </Route>
          <Route title="verification" exact path="/verified">
            {auth && auth.data ? <Verified /> : <Login></Login>}
          </Route>
          <Route title="orders" exact path="/orders">
            {auth && auth.data ? <Orders /> : <Login></Login>}
          </Route>
          <Route title="login" exact path="/login">
            {auth && auth.data ? <Index></Index> : <Login></Login>}
            {/* <Login /> */}
          </Route>
          {/* <Route title="mediumBooks" exact path="/mediumBooks">
          <MediumBooks />
        </Route> */}
          {/* <Route title="newBooks" exact path="/newBooks">
          <NewBooks />
        </Route> */}
          {/* <Route title="prizeBooks" exact path="/prizeBooks">
          <PrizeBooks />
        </Route> */}
          <Route title="usedBooks" exact path="/usedBooks">
            <UsedBooks />
          </Route>
          <Route title="single-post" exact path="/singlePost">
            <SinglePost />
          </Route>
          <Route title="book" exact path="/book/:id">
            <Book />
          </Route>
          <Route title="cart" exact path="/cart">
            <Cart />
          </Route>
          <Route title="profile" exact path="/profile">
            <Profile />
          </Route>
          <Route title="books" exact path="/books_by_category">
            <BooksByCategory />
          </Route>
          <Route title="books_by_series" exact path="/books_by_series">
            <BooksSeries />
          </Route>
          <Route title="featured" exact path="/featured">
            <FeaturedBooks />
          </Route>
          <Route title="further" exact path="/furthercoming">
            <FurtherComing />
          </Route>
          <Route title="events" exact path="/events">
            <Events />
          </Route>
          <Route title="in books love" exact path="/inBooksLove">
            <InBooksLove />
          </Route>
          <Route title="404">
            <Four />
          </Route>
        </Switch>
      </Box>
      <Footer />
      <Up />
      {isSmallScreen && (
        <Headroom
          className="navbar"
          style={{
            position: 'fixed',
            bottom: '0',
            top: 'unset',
            left: '0',
            right: '0',
            zIndex: '1',
            transform: 'translate3d(0px, 0px, 0px)',
            transition: 'all .5s ease-in-out 0s',
          }}
        >
          <Navbar />
        </Headroom>
      )}
      {!isSmallScreen && <Navbar />}
      <MessengerCustomerChat pageId="100298032209373" appId="387957032206747" />
    </>
  );
}
const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(App);
