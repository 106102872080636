import React from 'react';
import { Box, Heading, Text, Flex } from '@chakra-ui/core';
import { FaFacebookMessenger, FaWhatsapp } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

export default function standards() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>اتصل بموتاب</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
      >
        اتصل بنا
      </Heading>
      <Box
        textAlign="right"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Heading size="lg" m="4">
          {' '}
          يمكنكم الاتصال بنا على الإيميل:{' '}
        </Heading>
        <Text fontSize="2xl" m="4">
          mutab@almutawassit.org
        </Text>
        <Heading size="lg" m="4">
          {' '}
          أو على الرقم:{' '}
        </Heading>
        <Text fontSize="2xl" m="4">
          +393477104442{' '}
        </Text>
        <Flex fontSize="2xl" m="4">
          أو
          <Box mr="8">
              <a
                href="https://m.me/100298032209373"
                target="_blank"
              >
                  <FaFacebookMessenger></FaFacebookMessenger>
              </a>
          </Box>
        </Flex>
        <Flex fontSize="2xl" m="4">
          أو
          <Box mr="8">
              <a
                  href="https://wa.me/393477104442"
                  target="_blank"
              >
                  <FaWhatsapp></FaWhatsapp>
              </a>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
