import React from 'react';
import { Box, Text, Heading, Link } from '@chakra-ui/core';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function blog() {
  return (
    <Box mt={{ base: '2em', md: '8em' }} mb="100px">
      <Helmet>
        <title> سياسة الشحن</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
        سياسة الشحن{' '}
      </Heading>

      <Box
        textAlign="right"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          يعتمد متجر المتوسط سياسة ثابتة لشحن الكتب غرضها تخفيف أو إلغاء كلفة
          الشحن عن القارئ:
        </Text>
        <br />
        <Heading size="lg">إيطاليا وأوروبا:</Heading>
        <Text fontSize="2xl" m="4">
          داخل إيطاليا الشحن مجاني بغض النظر عن عدد الكتب المشتراة.{' '}
        </Text>
        <br />
        <Heading size="lg">داخل أوروبا:</Heading>
        <Text fontSize="2xl" m="4">
          كتاب واحد يضاف 10 يورو للشحن ☹
        </Text>
        <Text fontSize="2xl" m="4">
          كتابان يضاف 10 يورو للشحن ☹{' '}
        </Text>
        <Text fontSize="2xl" m="4">
          ثلاثة كتب يضاف 10 يورو للشحن ☹{' '}
        </Text>
        <Text fontSize="2xl" m="4">
          الحل الأمثل هو أن تشتري أربعة كتب وأكثر حينها يكون الشحن مجاناً لجميع
          دول أوروبا 😊 (إذا لم تكن بحاجة لشراء هذا العدد من الكتب ننصحك
          بالاتفاق مع صديق أو أكثر وعمل عملية شراء واحدة لكم).
        </Text>
        <br />
        <Heading size="lg">باقي دول العالم:</Heading>
        <Text fontSize="2xl" m="4">
          العرض الوحيد حالياً هو شراء 20 كتاباً على الأقل ليصبح الشحن مجانياً
          (تذكروا دائماً أنه يمكنكم الاتفاق مع مجموعة من الأصدقاء وشراء الكتب
          بعملية واحدة).
        </Text>
        <Text fontSize="2xl" m="4">
          يمكنكم دائما التواصل معنا مباشرة{' '}
          <Link href="https://m.me/100298032209373" isExternal>
            (انقر هنا)
          </Link>{' '}
          أو{' '}
          <Link href="https://wa.me/393477104442" isExternal>
            (هنا)
          </Link>{' '}
          ونرى بشكل مباشر ما هي كلفة الشحن وما هي إمكانية التخفيض الخاص.
        </Text>

        <br />
        <Heading size="lg">كتب المتوسط في العالم العربي:</Heading>
        <Text fontSize="2xl" m="4">
          حالياً إذا كنتم من سكان العراق أو لبنان فالشحن مجاني 😊 على جميع كتب
          منشورات المتوسط (فقط) ويمكنكم التواصل معنا مباشرة على الماسنجر{' '}
          <Link href="https://m.me/almutawassit" isExternal>
            {' '}
            من هنا
          </Link>{' '}
          .
        </Text>
        <Text fontSize="2xl" m="4">
          تعمل إدارة مكتبة (موتاب Mutab) حالياً على جمع عروض أسعار وإيجاد طرق
          مناسبة لتوفير الكتاب لزبائنها بأقل التكاليف الممكنة.
        </Text>
        <Text fontSize="2xl" m="4">
          تذكروا دائماً أن متجر (موتاب Mutab) غير معني بالربح مثل ما هو معني
          بتوفير الكتاب للقراء، لذا فإننا مستعدون دائماً للتنازل عن قدر من
          أرباحنا (وليس كلها طبعاً 😊) لأجل أن يكون الكتاب بين يديك.
        </Text>
      </Box>
    </Box>
  );
}
