import axios from 'axios';

export const getProfile = page => async (dispatch, getState) => {
  console.log(page);
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .get(`${process.env.REACT_APP_API}/profile`, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
export const postProfile = profile => async (dispatch, getState) => {
  console.log(profile);
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .post(`${process.env.REACT_APP_API}/profile`, profile, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};

export const updatePassword = payload => async (dispatch, getState) => {
  console.log(payload);
  const { password, new_password, new_password_confirmation } = payload;
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .post(`${process.env.REACT_APP_API}/updatePassword`, payload, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
