import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Box,
  Image,
  Heading,
  Flex,
  Button,
  useToast,
  useColorMode,
  useBreakpointValue,
  useMediaQuery,
  List,
  ListItem,
} from '@chakra-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  removeBook,
  clearCart,
  removeItalianBook,
} from '../redux/actions/cartActions';
import Quantity from '../components/cart/Quantity';
import emptyCart from '../images/empty-cart.svg';
import moment from 'moment';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Pay
      </button>
    </form>
  );
};

const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');

function Cart({ cart, removeBook, removeItalianBook, clearCart }) {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const bg = { light: 'white', dark: '#151a23' };
  const isBreakPointSm = useBreakpointValue({ base: true, md: false });
  const [isSmallerThan500] = useMediaQuery('(max-width: 500px)');
  const [isSmallerThan590] = useMediaQuery('(max-width: 590px)');

  return (
    <Box mt="50px" p={{ base: '1em', md: '2em', lg: '3em' }}>
      {cart &&
      cart.books &&
      cart.italianBooks &&
      cart.books.length === 0 &&
      cart.italianBooks.length === 0 ? (
        <Flex
          mt="160px"
          mb="160px"
          justifyContent="center"
          alignItems="center"
          direction="column"
          px="1em"
        >
          <Heading mb="8" textAlign="center">
            سلة المشتريات الخاصة بك لا تزال فارغة{' '}
          </Heading>
          <Image loading="lazy" w="15%" src={emptyCart}></Image>
        </Flex>
      ) : (
        <Box bg={bg[colorMode]}>
          {cart &&
            cart.italianBooks &&
            cart.italianBooks.map(item => {
              return (
                <Box
                  key={item.book.id}
                  borderBottom="2px solid"
                  borderColor="gray.100"
                  py="2em"
                  px={{ base: '1em', md: '2em', xl: '5em' }}
                >
                  <Flex mb="1em" justifyContent="space-between">
                    <Flex direction={{ base: 'column', sm: 'row' }}>
                      <Image
                        loading="lazy"
                        w="200px"
                        ml="1.5em"
                        src={`${process.env.REACT_APP_STORAGE}/${item.book.cover}`}
                      />
                      <Box mt={{ base: '1em', md: '0' }}>
                        <Heading>{item.book.title}</Heading>
                        <List mt="4" fontSize="1.25rem">
                          <ListItem>الناشر : {item.book.publisher} </ListItem>
                          <ListItem>
                            تاريخ النشر :{' '}
                            {moment(item.publish_date).format('yyyy/MM/DD')}
                          </ListItem>
                          <ListItem>
                            عدد الصفحات : {item.book.page_number}{' '}
                          </ListItem>
                          <ListItem dir="ltr">
                            {' '}
                            {item.book.isbn} : ISBN{' '}
                          </ListItem>
                          <ListItem>السعر : {item.book.price}€</ListItem>
                        </List>
                      </Box>
                    </Flex>
                    {!isSmallerThan590 && (
                      <Button
                        mr="2em"
                        colorScheme="red"
                        variant="solid"
                        onClick={() => {
                          removeItalianBook(item);
                          toast({
                            //  title: 'Account created.',
                            description: 'الكتاب حذف من سلة المشتريات ',
                            position: 'top-right',
                            status: 'error',
                            duration: 2000,
                            isClosable: true,
                          });
                        }}
                      >
                        حذف
                      </Button>
                    )}
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Quantity item={item} qnt={item.qnt} type="it" />
                    {isSmallerThan590 && (
                      <Button
                        mr=".8em"
                        colorScheme="red"
                        variant="solid"
                        onClick={() => {
                          removeItalianBook(item);
                          toast({
                            //  title: 'Account created.',
                            description: 'الكتاب حذف من سلة المشتريات ',
                            position: 'top-right',
                            status: 'error',
                            duration: 2000,
                            isClosable: true,
                          });
                        }}
                      >
                        حذف
                      </Button>
                    )}
                  </Flex>
                </Box>
              );
            })}
          {cart &&
            cart.books &&
            cart.books.map(item => {
              return (
                <Box
                  key={item.book.id}
                  borderBottom="2px solid"
                  borderColor="gray.100"
                  py="2em"
                  px={{ base: '1em', md: '2em', xl: '5em' }}
                >
                  {console.log('ITEM', item)}
                  <Flex mb="1em" justifyContent="space-between">
                    <Flex direction={{ base: 'column', sm: 'row' }}>
                      <Image
                        loading="lazy"
                        w="200px"
                        ml="1.5em"
                        src={`${process.env.REACT_APP_STORAGE}/${item.book.cover}`}
                      />
                      <Box mt={{ base: '1em', md: '0' }}>
                        <Heading>{item.book.title}</Heading>
                        <List mt="4" fontSize="1.25rem">
                          <ListItem>
                            الناشر : {item.book.publisher.name}{' '}
                          </ListItem>
                          <ListItem>
                            تاريخ النشر :{' '}
                            {moment(item.publish_date).format('yyyy/MM/DD')}
                          </ListItem>
                          <ListItem>
                            عدد الصفحات : {item.book.page_number}{' '}
                          </ListItem>
                          <ListItem dir="ltr">
                            {' '}
                            {item.book.isbn} : ISBN{' '}
                          </ListItem>
                          <ListItem>السعر : {item.book.price}€</ListItem>
                        </List>
                      </Box>
                    </Flex>
                    {!isSmallerThan590 && (
                      <Button
                        mr="2em"
                        colorScheme="red"
                        variant="solid"
                        onClick={() => {
                          removeBook(item);
                          toast({
                            //  title: 'Account created.',
                            description: 'الكتاب حذف من سلة المشتريات ',
                            position: 'top-right',
                            status: 'error',
                            duration: 2000,
                            isClosable: true,
                          });
                        }}
                      >
                        حذف
                      </Button>
                    )}
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Quantity item={item} qnt={item.qnt} type="ar" />
                    {isSmallerThan590 && (
                      <Button
                        mr=".8em"
                        colorScheme="red"
                        variant="solid"
                        onClick={() => {
                          removeBook(item);
                          toast({
                            //  title: 'Account created.',
                            description: 'الكتاب حذف من سلة المشتريات ',
                            position: 'top-right',
                            status: 'error',
                            duration: 2000,
                            isClosable: true,
                          });
                        }}
                      >
                        حذف
                      </Button>
                    )}
                  </Flex>
                </Box>
              );
            })}
          <Flex
            px={{ base: '1em', md: '2em', xl: '5em' }}
            direction={isSmallerThan500 ? 'column' : 'row'}
            p={{ base: '1em', md: '2em' }}
            justifyContent="space-between"
          >
            <Heading size="lg" mb={['4', '4', '0', '0']}>
              المبلغ الاجمالي: {cart && cart.totalPrice}€
            </Heading>
            <Box>
              <Button
                ml="1em"
                fontSize={['sm', 'sm', 'lg', 'lg']}
                _hover={{ bg: 'gray.100', color: 'black' }}
                bg="red.500"
                color="white"
                onClick={() => {
                  clearCart();
                  toast({
                    //  title: 'Account created.',
                    description: 'تم افراغ سلة المشتريات ',
                    position: 'top-right',
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                  });
                }}
              >
                افراغ السلة
              </Button>
              <Link to="/checkout">
                <Button
                  fontSize={['sm', 'sm', 'lg', 'lg']}
                  _hover={{ bg: 'gray.100', color: 'black' }}
                  bg="black"
                  color="white"
                >
                  اكمل الطلب{'  >>'}
                </Button>
              </Link>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return { cart: state.cart };
};

const mapDispatchToProps = dispatch => {
  return {
    removeBook: item => dispatch(removeBook(item)),
    removeItalianBook: item => dispatch(removeItalianBook(item)),
    clearCart: item => dispatch(clearCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
