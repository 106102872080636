import * as t from '../types';
import axios from 'axios';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getBooks =
  (category, featured, translate, furthercoming) =>
  async (dispatch, getState) => {
    console.log(category, featured, translate, furthercoming);
    let data;
    if (!category && !featured && !translate && !furthercoming) {
      console.log('all books no filter');
      data = await axios
        .get(`${process.env.REACT_APP_API}/books?website=منشورات المتوسط`, {
          headers,
        })
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (!category && featured && !translate && !furthercoming) {
      console.log('just featured');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&featured=${featured}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (!category && featured && translate && !furthercoming) {
      console.log('feautred with transltae');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&featured=${featured}&translate=${translate}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (category && !featured && !translate && !furthercoming) {
      console.log('just category');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&category=${category}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (!category && !featured && !translate && furthercoming) {
      console.log('just category');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&furthercoming=${furthercoming}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (!category && !featured && translate && !furthercoming) {
      console.log('just translate');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&translate=${translate}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (category && !featured && translate && !furthercoming) {
      console.log('category with translate');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&category=${category}&translate=${translate}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    } else if (!category && !featured && translate && furthercoming) {
      console.log('furthercoming with translate');
      data = await axios
        .get(
          `${process.env.REACT_APP_API}/books?website=منشورات المتوسط&furthercoming=${furthercoming}&translate=${translate}`,
          { headers }
        )
        .then(res => {
          console.log(res);
          return res;
        })
        .catch(err => {
          console.log(err);
        });
    }
    return data;
  };

export const getBooksBySerie = serie => async (dispatch, getState) => {
  console.log(serie);
  let data;
  data = await axios
    .get(
      `${process.env.REACT_APP_API}/books?serie=${serie}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};

export const getBook = id => async (dispatch, getState) => {
  const data = await axios
    .get(
      `${process.env.REACT_APP_API}/books/${id}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      //   console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};

/** REVIEW See if it works */

export const getItalianBook = id => async (dispatch, getState) => {
  const data = await axios
    .get(
      `${process.env.REACT_APP_API}/italianBooks/${id}`,
      //   { website: 'المتوسط' },
      { headers }
    )
    .then(res => {
      //   console.log(res);
      return res;
    })
    .catch(err => {
      console.log('Error', err);
    });
  return data;
};
