import { combineReducers } from 'redux';

import authReducer from './authReducer';
import cartReducer from './cartReducer';
import ipReducer from './ipReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  ip: ipReducer,
});

export default rootReducer;
