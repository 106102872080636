import * as t from '../types.js';

const initialState = {
  books: [],
  italianBooks: [],
  totalPrice: 0,
  cartItemNumber: 0,
  shippingPrice: 0,
  totalPriceAfterShipping: 0,
};

let newBooks;
let price;
let cartNumber;
let index;
let newBookObject;

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.CLEAR_CART:
      return {
        books: [],
        italianBooks: [],
        totalPrice: 0,
        cartItemNumber: 0,
      };

    case t.QNT_UP:
      console.log('ACTION PAYLOAD', action.payload);

      newBooks = state.books.filter((res, i) => {
        if (res.book.id === action.payload.book.id) {
          index = i;
        }
        return res.book.id !== action.payload.book.id;
      });
      newBookObject = {
        ...action.payload,
        qnt: action.payload.qnt + 1,
      };
      newBooks.splice(index, 0, newBookObject);
      return {
        ...state,
        books: newBooks,
        totalPrice:
          parseFloat(state.totalPrice) + parseFloat(action.payload.book.price),
      };

    case t.QNT_UP_IT:
      console.log('ACTION PAYLOAD', action.payload);

      newBooks = state.italianBooks.filter((res, i) => {
        if (res.book.id === action.payload.book.id) {
          index = i;
        }
        return res.book.id !== action.payload.book.id;
      });
      newBookObject = {
        ...action.payload,
        qnt: action.payload.qnt + 1,
      };
      newBooks.splice(index, 0, newBookObject);
      return {
        ...state,
        italianBooks: newBooks,
        totalPrice:
          parseFloat(state.totalPrice) + parseFloat(action.payload.book.price),
      };

    case t.QNT_DOWN:
      console.log(action.payload);
      newBooks = state.books.filter((res, i) => {
        if (res.book.id === action.payload.book.id) {
          index = i;
        }
        return res.book.id !== action.payload.book.id;
      });
      newBookObject = {
        ...action.payload,
        qnt: action.payload.qnt - 1,
      };
      newBooks.splice(index, 0, newBookObject);
      return {
        ...state,
        books: newBooks,
        totalPrice:
          parseFloat(state.totalPrice) - parseFloat(action.payload.book.price),
      };

    case t.QNT_DOWN_IT:
      console.log(action.payload);
      newBooks = state.italianBooks.filter((res, i) => {
        if (res.book.id === action.payload.book.id) {
          index = i;
        }
        return res.book.id !== action.payload.book.id;
      });
      newBookObject = {
        ...action.payload,
        qnt: action.payload.qnt - 1,
      };
      newBooks.splice(index, 0, newBookObject);
      return {
        ...state,
        italianBooks: newBooks,
        totalPrice:
          parseFloat(state.totalPrice) - parseFloat(action.payload.book.price),
      };

    case t.ADD_BOOK:
      console.log(action.payload);
      if (state.books.length !== 0) {
        newBooks = [action.payload, ...state.books];
      } else {
        newBooks = [action.payload];
      }
      price = Number(state.totalPrice) + Number(action.payload.book.price);
      cartNumber = state.cartItemNumber + 1;
      return {
        ...state,
        books: newBooks,
        totalPrice: price.toFixed(2),
        cartItemNumber: cartNumber,
      };

    case t.ADD_ITALIAN_BOOK:
      console.log(action.payload);
      if (state.italianBooks.length !== 0) {
        newBooks = [action.payload, ...state.italianBooks];
      } else {
        newBooks = [action.payload];
      }
      price =
        parseFloat(state.totalPrice) + parseFloat(action.payload.book.price);
      cartNumber = state.cartItemNumber + 1;
      return {
        ...state,
        italianBooks: newBooks,
        totalPrice: price,
        cartItemNumber: cartNumber,
      };

    case t.REMOVE_BOOK:
      console.log(action.payload);
      newBooks = state.books.filter(item => {
        return item.book.id !== action.payload.book.id;
      });
      price = state.totalPrice - action.payload.book.price * action.payload.qnt;
      cartNumber = state.cartItemNumber - 1;
      return {
        ...state,
        books: newBooks,
        totalPrice: price,
        cartItemNumber: cartNumber,
      };

    case t.REMOVE_ITALIAN_BOOK:
      console.log(action.payload);
      newBooks = state.italianBooks.filter(item => {
        return item.book.id !== action.payload.book.id;
      });
      price = state.totalPrice - action.payload.book.price * action.payload.qnt;
      cartNumber = state.cartItemNumber - 1;
      return {
        ...state,
        italianBooks: newBooks,
        totalPrice: price,
        cartItemNumber: cartNumber,
      };
    case t.CHECK_SHIPPING:
      const totalAfterShipping = Number(state.totalPrice) + Number(action.payload)

      return {
        ...state,
        shippingPrice: action.payload,
        totalPriceAfterShipping: totalAfterShipping.toFixed(2),
      };

    default:
      return state;
  }
};

export default cartReducer;
