import { useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

const usePersistPagination = (key = 'currentPage', pathURL = 'pathURL') => {
  const [value, setValue] = useLocalStorage(key, 0);

  useEffect(() => {
    const pathname = localStorage.getItem(pathURL);
    if (!pathname || pathname !== window.location.pathname) {
      setValue(0);
      localStorage.setItem(pathURL, window.location.pathname);
    }
  }, []);

  return [value, setValue];
};

export default usePersistPagination;
