import * as t from '../types.js';

const initialState = {
  data: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case t.AUTH_SAVE:
      console.log(action.payload.data);
      return {
        ...state,
        data: action.payload.data,
      };
    case t.AUTH_LOGOUT:
      console.log('logout triggered');
      return {
        data: null,
      };

    default:
      return state;
  }
};

export default authReducer;
