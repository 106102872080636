import { Button, Center, Text, useToast } from '@chakra-ui/core';
import { FaShoppingBag, FaTrash } from 'react-icons/fa';
import React from 'react';
import {
  addBook,
  addItalianBook,
  removeBook,
  removeItalianBook,
} from '../redux/actions/cartActions';
import { connect } from 'react-redux';

function AddToCart({
  books,
  book,
  addBook,
  removeBook,
  addItalianBook,
  removeItalianBook,
  cart,
  type,
}) {
  const toast = useToast();
  // ?check if the book exist in cart
  const buttonState = book => {
    let checkIfExist;

    if (type === 'italian') {
      checkIfExist =
        cart &&
        books &&
        cart.italianBooks &&
        cart.italianBooks.map(item => {
          return item.book.id === book.id;
        });
    } else {
      checkIfExist =
        cart &&
        books &&
        cart.books &&
        cart.books.map(item => {
          return item.book.id === book.id;
        });
    }

    let exist = checkIfExist && checkIfExist.includes(true);
    return exist;
  };

  //?handle boook submit
  const handleSubmit = book => {
    let exist = buttonState(book);
    if (exist) {
      type === 'italian'
        ? removeItalianBook({ book: book, qnt: 1 })
        : removeBook({ book: book, qnt: 1 });
      toast({
        //  title: 'Account created.',
        description: 'الكتاب حذف من سلة المشتريات ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } else {
      type === 'italian'
        ? addItalianBook({ book: book, qnt: 1 })
        : addBook({ book: book, qnt: 1 });
      toast({
        //  title: 'Account created.',
        description: 'الكتاب اضيف الى سلة المشتريات بنجاح',
        position: 'top-right',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Center>
      <Button
        leftIcon={buttonState(book) ? <FaTrash /> : <FaShoppingBag />}
        onClick={() => handleSubmit(book)}
        rounded="20px"
        mt="4"
        fontSize={['xs', 'xs', 'sm', 'sm', 'md']}
        w={['90%', '90%', '90%', '90%']}
        colorScheme={buttonState(book) ? 'red' : 'green'}
        fontFamily="diodrum-med !important"
      >
        <Text mr={['1', '2']}>
          {buttonState(book) ? ' حذف من السلة ' : ' اضف الى السلة '}
        </Text>
      </Button>
    </Center>
  );
}

const mapStateToProps = state => {
  return { cart: state.cart, auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    addBook: book => dispatch(addBook(book)),
    removeBook: book => dispatch(removeBook(book)),
    addItalianBook: book => dispatch(addItalianBook(book)),
    removeItalianBook: book => dispatch(removeItalianBook(book)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
