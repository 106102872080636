import React from 'react';
import {
  Box,
  useColorMode,
  Flex,
  Input,
  Text,
  Button,
  Select,
  useToast,
} from '@chakra-ui/core';
import { connect } from 'react-redux';
import { validateAdress } from '../redux/actions/checkoutActions';

import { countires } from '../util/countries';

function Checkout({ validateAdress }) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: '#151a23' };
  const [loading, setLoading] = React.useState(false);

  //?Checkout step 1 start here...
  const checkoutState1 = {
    country: '',
    city: '',
    province: '',
    zip_code: '',
    address: '',
    phone: '',
  };

  const [checkout1Info, setCheckout1Info] = React.useState(checkoutState1);

  const handleCheckout1Change = async e => {
    e.persist();
    await setCheckout1Info(prevState => ({
      ...prevState,
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    console.log(checkout1Info);
  };

  const handleCheckout1Submit = async () => {
    console.log(checkout1Info);
    setLoading(true);
    const res = await validateAdress(checkout1Info);
    if (res?.status === 202) {
      //   toast({
      //     //  title: 'Account created.',
      //     description: 'المعلومات  مقبولة  ',
      //     position: 'top-right',
      //     status: 'success',
      //     duration: 2000,
      //     isClosable: true,
      //   });
      setLoading(false);
      window.location.replace('/checkout_pay');
    } else {
      toast({
        //  title: 'Account created.',
        description: 'المعلومات ليست مقبولة  ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  //?Checkout setp 1 end here

  return (
    <Box mt="100px" mb="100px">
      <Flex justifyContent="center">
        <Box minW="50%" p="8" shadow="lg" bg={bg[colorMode]}>
          <Text mb="4">البلد</Text>
          <Select
            onChange={handleCheckout1Change}
            mb="4"
            type="text"
            name="country"
            placeholder="البلد"
          >
            {countires.map(country => (
              <option key={country[0]} value={country[0]}>
                {country[1].ar_name}
              </option>
            ))}
          </Select>
          <Text mb="4">الولاية</Text>
          <Input
            onChange={handleCheckout1Change}
            value={checkout1Info.city}
            mb="4"
            type="text"
            name="city"
            placeholder="الولاية"
          ></Input>
          <Text mb="4">البلدية</Text>
          <Input
            onChange={handleCheckout1Change}
            value={checkout1Info.province}
            mb="4"
            type="text"
            name="province"
            placeholder="البلدية"
          ></Input>
          <Text mb="4">الرمز البريدي</Text>
          <Input
            onChange={handleCheckout1Change}
            value={checkout1Info.zip_code}
            mb="4"
            type="text"
            name="zip_code"
            placeholder="الرمز البريدي"
          ></Input>
          <Text mb="4">العنوان</Text>
          <Input
            onChange={handleCheckout1Change}
            value={checkout1Info.address}
            mb="4"
            type="text"
            name="address"
            placeholder="العنوان"
          ></Input>
          <Text mb="4">رقم الهاتف</Text>
          <Input
            onChange={handleCheckout1Change}
            value={checkout1Info.phone}
            mb="4"
            type="text"
            name="phone"
            placeholder="رقم الهاتف"
          ></Input>
          <Button
            isDisabled={
              checkout1Info.country === '' ||
              checkout1Info.province === '' ||
              checkout1Info.city === '' ||
              checkout1Info.zip_code === '' ||
              checkout1Info.phone === '' ||
              checkout1Info.address === '' ||
              loading
            }
            isLoading={loading}
            onClick={handleCheckout1Submit}
            w="100%"
            mt="4"
            colorScheme="teal"
          >
            أكمل الطلب
          </Button>
        </Box>
      </Flex>
      {/* <Grid
        pr={['2%', '2%', '7%', '7%']}
        pl={['2%', '2%', '7%', '7%']}
        templateColumns={['1fr', '1fr', '1fr 2fr', '1fr 2fr']}
        gap="10px"
      >
        <Box p="8" shadow="lg" bg={bg[colorMode]} w="100%">
          <Heading>Info</Heading>
        </Box>

        <Box p="8" shadow="lg" bg={bg[colorMode]} w="100%">
          <Heading>Info</Heading>
        </Box>
      </Grid> */}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { validateAdress: payload => dispatch(validateAdress(payload)) };
};

export default connect(null, mapDispatchToProps)(Checkout);
