import React from 'react';
import {
  Box,
  Grid,
  useColorMode,
  Flex,
  Text,
  Button,
  Select,
  useToast,
  Divider,
  Spinner,
  Image,
} from '@chakra-ui/core';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { shippingRates } from '../redux/actions/checkoutActions';
import { clearCart, checkShipping } from '../redux/actions/cartActions';

function CheckoutPay({ shippingRates, cart, auth, clearCart, checkShipping }) {
  console.log(cart);
  const toast = useToast();

  const stripe = useStripe();
  const elements = useElements();

  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: '#151a23' };
  const [loading, setLoading] = React.useState(false);
  const payState = { methods: '', amount: '', books: '' };
  const [pay, setPay] = React.useState(payState);

  const handleSubmit = async () => {
    // setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      return;
    }
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: auth.email,
      },
    });
    console.log(result);
    setLoading(true);
    if (result.error) {
      setLoading(false);

      console.log(result.error.message);
      toast({
        //  title: 'Account created.',
        description: result.error.message,
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } else {
      const res = await shippingRates({
        ...pay,
        payment_method: result.paymentMethod.id,
      });
      if (res?.status === 200) {
        toast({
          //  title: 'Account created.',
          description: 'تمت عملية الدفع بنجاح',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        // setLoading(false);

        console.log(res);
        clearCart();
        setTimeout(() => {
          window.location.replace('/orders');
        }, 2000);
      } else {
        setLoading(false);
        toast({
          //  title: 'Account created.',
          description: res.data.message,
          position: 'top-right',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleSubmitPaypal = async () => {
    setLoading(true);
    const res = await shippingRates({
      ...pay,
    });
    if (res?.status === 200) {
      if (res.data.paypal_link) {
        // window.open(res.data.paypal_link, '_blank');
        window.location.replace(res.data.paypal_link);
      }
      // toast({
      //   //  title: 'Account created.',
      //   description: 'تمت عملية الدفع بنجاح',
      //   position: 'top-right',
      //   status: 'success',
      //   duration: 2000,
      //   isClosable: true,
      // });
      // setLoading(false);

      console.log(res);
      // clearCart();
      // setTimeout(() => {
      //   window.location.replace('/orders');
      // }, 2000);
    } else {
      setLoading(false);
      toast({
        //  title: 'Account created.',
        description: 'حدث خطا ما اعد المحاولة ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleChange = e => {
    e.persist();
    setPay(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  };

  React.useEffect(() => {
    checkShipping();

    /**REVIEW See if it works */
    if (
      cart?.totalPrice !== 0 &&
      (cart?.books.length !== 0 || cart?.italianBooks !== 0)
    ) {
      const allBooks = [...cart.books, ...cart.italianBooks];
      const books = [];
      allBooks.map(bookObj => {
        books.push({
          title: bookObj.book.title,
          price: bookObj.book.price,
          length: bookObj.book.length,
          width: bookObj.book.width,
          height: bookObj.book.height,
          distance_unit: bookObj.book.distance_unit,
          weight: bookObj.book.weight,
          mass_unit: bookObj.book.mass_unit,
          qnt: bookObj.qnt,
        });
      });
      console.log(books);
      setPay(prevState => ({
        ...prevState,
        amount: cart.totalPriceAfterShipping * 100,
        books: JSON.stringify({ books }),
      }));
    }
  }, [cart.books, cart.totalPriceAfterShipping, cart.totalPrice]);

  console.log(pay);

  return (
    <Box mt="100px" mb="100px">
      {loading ? (
        <Flex justifyContent="center" p="8">
          <Spinner size="xl"></Spinner>
        </Flex>
      ) : (
        <Grid
          pr={['2%', '2%', '7%', '7%']}
          pl={['2%', '2%', '7%', '7%']}
          templateColumns={['1fr', '1fr', '0.5fr 1fr', '0.5fr 1fr']}
          gap="10px"
        >
          <Box
            fontFamily="diodrum-med !important"
            p="8"
            shadow="lg"
            bg={bg[colorMode]}
          >
            {cart.books.map(book => (
              <Flex mb="4">
                <Image
                  w="80px"
                  src={`${process.env.REACT_APP_STORAGE}/${book.book.cover}`}
                ></Image>
                <Box mr="4">
                  <Text fontSize="lg" mb="2">
                    السعر : {book.book.price}€
                  </Text>
                  <Text fontSize="lg" mb="2">
                    الكمية : {book.qnt}
                  </Text>
                </Box>
              </Flex>
            ))}

            <Divider mb="4"></Divider>
            <Text fontSize="lg" mb="2">
              سعر الكتب : {cart.totalPrice}€
            </Text>
            <Text fontSize="lg" mb="4">
              سعر الشحن المضاف : {cart.shippingPrice}€
            </Text>
            <Divider mb="4"></Divider>
            <Text fontWeight="bold" fontSize="xl">
              المبلغ الاجمالي : {cart.totalPriceAfterShipping}€
            </Text>
          </Box>
          <Box>
            <Box
              fontFamily="diodrum-med !important"
              minW="50%"
              p="8"
              shadow="lg"
              bg={bg[colorMode]}
            >
              <Text mb="4">طريقة الدفع</Text>
              <Select
                onChange={handleChange}
                mb="4"
                type="text"
                name="methods"
                placeholder="طريقة الدفع"
              >
                {/* <option disabled={true} value="cash">
                  cash
                </option> */}
                <option value="visa">البطاقة البنكية (visa/mastercard)</option>
                <option value="paypal">بايبال(paypal)</option>
              </Select>
              {pay.methods === 'visa' && (
                <>
                  <Text mb="4">معلومات البطاقة المصرفية</Text>
                  <CardElement></CardElement>
                </>
              )}
              <Button
                isDisabled={
                  pay.methods === '' ||
                  pay.amount === '' ||
                  pay.books === '' ||
                  loading
                }
                isLoading={loading}
                onClick={
                  pay.methods === 'visa' ? handleSubmit : handleSubmitPaypal
                }
                w="100%"
                mt="4"
                colorScheme="teal"
              >
                أكمل الطلب
              </Button>
            </Box>
          </Box>
        </Grid>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return { cart: state.cart, auth: state.auth };
};
const mapDispatchToProps = dispatch => {
  return {
    shippingRates: payload => dispatch(shippingRates(payload)),
    clearCart: () => dispatch(clearCart()),
    checkShipping: () => dispatch(checkShipping()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPay);
