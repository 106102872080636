import React, { useState, useRef } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Box,
  Heading,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  useDisclosure,
  Image,
  useColorMode,
  Stack,
  useMediaQuery,
  useBreakpointValue,
} from '@chakra-ui/core';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';
// import { ChevronDownIcon, Search2Icon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/authAction';

import {  FaChevronUp, FaMoon, FaSun } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
import logo from '../../images/shop-logo.png';
// import Up from '../../util/Up';

function Navbar({ auth, logout }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
 
  const toast = useToast();
  const [isSmallerThan1280] = useMediaQuery('(max-width: 1280px)');

  const [show, setShow] = useState(false);
  const btnRef = useRef();

  const bg = { light: '#000000', dark: '#1a202c' };
  const color = { light: 'white', dark: 'white' };

  const handleToggle = () => setShow(!show);

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box>
      <Flex
        style={
          !isSmallScreen
            ? {
                position: 'fixed',
                width: '100%',
                zIndex: '99',
                bottom: 0,
                right: 0,
              }
            : {}
        }
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="0.5rem"
        color={color[colorMode]}
        bg={bg[colorMode]}
      >
        <Flex align="center" mx="1.5vw">
          <Link to="/">
            <Heading
              cursor="pointer"
              fontWeight="bold"
              as="h1"
              fontSize="36px"
              letterSpacing={'-.1rem'}
            >
              <Image
                loading="lazy"
                w="150px"
                className="main-logo"
                src={logo}
              ></Image>
            </Heading>
          </Link>
        </Flex>

        <Box display={isSmallerThan1280 ? 'block' : 'none'} onClick={onOpen}>
          <GiHamburgerMenu style={{ marginLeft: 10, fontSize: 28 }} />
        </Box>
        {/* // display={{ base: show ? 'block' : 'none', md: 'flex' }} */}
        <Box
          display={isSmallerThan1280 ? 'none' : 'flex'}
          width={{ base: 'full', md: 'auto' }}
          alignItems="center"
          textAlign="center"
          justifyContent="flex-end"
          flexGrow={1}
        >
          {/** NOTE ITALIAN BOOK */}
          {/*           
          <Text
            fontFamily="diodrum-bold !important"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontWeight="bold"
            fontSize="lg"
          >
            <Link to="/italian/shopbooks">كشاف الكتب الايطالية</Link>
          </Text> */}
          <Text
            fontFamily="diodrum-bold !important"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontWeight="bold"
            fontSize="lg"
          >
            <Link to="/shopbooks">كشاف الكتب</Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontWeight="bold"
            fontSize="lg"
          >
            <Link to="/shipping">الشحن والتوفير</Link>
          </Text>
          <Menu>
            <MenuButton
              // px={4}
              // py={2}
              mt={{ base: 4, md: 0 }}
              ml="1.5vw"
              fontWeight="bold"
              fontSize="lg"
              transition="all 0.2s"
              fontFamily="diodrum-bold !important"

              // _hover={{ bg: 'gray.100' }}
              // _focus={{ outline: 0, boxShadow: 'outline' }}
            >
              كتب وقوائم مختارة
              <FaChevronUp style={{ display: 'inline' }}></FaChevronUp>
            </MenuButton>
            <MenuList bg={bg[colorMode]} shadow="xl">
              <Link onClick={handleToggle} to="/writers">
                <MenuItem
                  _focus={{ bg: 'white', color: 'black' }}
                  _hover={{ bg: 'white', color: 'black' }}
                  fontSize="xl"
                >
                  <Box display="flex">
                    <Text>كُتّاب اختاروا لك</Text>
                  </Box>
                </MenuItem>
              </Link>
              <Link onClick={handleToggle} to="/25books?mutab_featurd=25before">
                <MenuItem
                  _focus={{ bg: 'white', color: 'black' }}
                  _hover={{ bg: 'white', color: 'black' }}
                  fontSize="xl"
                >
                  <Box display="flex">
                    <Text>كتب يجب أن تقرأها قبل أن تموت</Text>
                  </Box>
                </MenuItem>
              </Link>
              <Link onClick={handleToggle} to="/25books?mutab_featurd=25start">
                <MenuItem
                  _focus={{ bg: 'white', color: 'black' }}
                  _hover={{ bg: 'white', color: 'black' }}
                  fontSize="xl"
                >
                  <Box display="flex">
                    <Text>كتب لتبدأ بها القراءة</Text>
                  </Box>
                </MenuItem>
              </Link>
              {/*<Link onClick={handleToggle} to="/25books?mutab_featurd=25chosen">*/}
              {/*  <MenuItem*/}
              {/*    _focus={{ bg: 'white', color: 'black' }}*/}
              {/*    _hover={{ bg: 'white', color: 'black' }}*/}
              {/*    fontSize="xl"*/}
              {/*  >*/}
              {/*    <Box display="flex">*/}
              {/*      <Text>كُتّاب اختاروا لك</Text>*/}
              {/*    </Box>*/}
              {/*  </MenuItem>*/}
              {/*</Link>*/}
            </MenuList>
          </Menu>
          {/* <Menu>
          <MenuButton
            // px={4}
            // py={2}
            mt={{ base: 4, md: 0 }}
            ml={8}
            fontSize="lg"
            transition="all 0.2s"
            fontFamily="diodrum-bold !important"

            // _hover={{ bg: 'gray.100' }}
            // _focus={{ outline: 0, boxShadow: 'outline' }}
          >
            قارئ بائع كتب
            <FaChevronUp style={{ display: 'inline' }}></FaChevronUp>
          </MenuButton>
          <MenuList bg={bg[colorMode]} shadow="xl">
            <Link onClick={handleToggle} to="/info">
              <MenuItem  _focus={{ bg: 'white', color: 'black' }}
                  _hover={{ bg: 'white', color: 'black' }}
                  fontSize="xl">
                <Box display="flex">
                  <Text>بع كتبك هنا</Text>
                </Box>
              </MenuItem>
            </Link>
            <Link onClick={handleToggle} to="/favories">
              <MenuItem  _focus={{ bg: 'white', color: 'black' }}
                  _hover={{ bg: 'white', color: 'black' }}
                  fontSize="xl">
                <Box display="flex">
                  <Text>استبدل كتبك بكتب أخرى</Text>
                </Box>
              </MenuItem>
            </Link>
          </MenuList>
        </Menu> */}
          <Text
            fontFamily="diodrum-bold !important"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontWeight="bold"
            fontSize="lg"
          >
            <Link onClick={handleToggle} to="/inBooksLove">
              في حب القراءة
            </Link>
          </Text>
          <Text
            fontFamily="diodrum-bold !important"
            mt={{ base: 4, md: 0 }}
            ml="1.5vw"
            display="block"
            fontWeight="bold"
            fontSize="lg"
          >
            <Link onClick={handleToggle} to="/events">
              نشاطات المكتبة
            </Link>
          </Text>
          <Menu>
            <MenuButton
              // px={4}
              // py={2}
              mt={{ base: 4, md: 0 }}
              ml="1.5vw"
              fontWeight="bold"
              fontSize="lg"
              transition="all 0.2s"
              fontFamily="diodrum-bold !important"

              // _hover={{ bg: 'gray.100' }}
              // _focus={{ outline: 0, boxShadow: 'outline' }}
            >
              الحساب <FaChevronUp style={{ display: 'inline' }}></FaChevronUp>
            </MenuButton>
            <MenuList bg={bg[colorMode]} shadow="xl">
              {auth && !auth.data && (
                <>
                  <Link to="/login">
                    <MenuItem
                      onClick={handleToggle}
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      تسجيل الدخول
                    </MenuItem>
                  </Link>
                  <Link to="/register">
                    <MenuItem
                      onClick={handleToggle}
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      تسجيل جديد
                    </MenuItem>
                  </Link>
                </>
              )}

              {auth.data && (
                <>
                  <Link to="/profile">
                    <MenuItem
                      onClick={handleToggle}
                      fontFamily="diodrum-med !important"
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      حسابي
                    </MenuItem>
                  </Link>

                  <Link to="/orders">
                    <MenuItem
                      onClick={handleToggle}
                      fontFamily="diodrum-med !important"
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                    >
                      مشترياتي
                    </MenuItem>
                  </Link>

                  <MenuItem
                    fontFamily="diodrum-med !important"
                    _focus={{ bg: 'white', color: 'black' }}
                    _hover={{ bg: 'white', color: 'black' }}
                    onClick={() => {
                      logout();
                      handleToggle();
                      // window.location.replace('/');
                    }}
                    fontSize="xl"
                  >
                    خروج
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
          <Box
            cursor="pointer"
            ml=".8vw"
            mt={['6', '6', '0', '0']}
            fontSize="24px"
            onClick={toggleColorMode}
          >
            {' '}
            {colorMode === 'light' ? <FaMoon></FaMoon> : <FaSun></FaSun>}
          </Box>
          {/* <Up></Up> */}
        </Box>
      </Flex>

      <Drawer
        // style={{dir:"rtl"}}

        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent color={color[colorMode]} bg={bg[colorMode]}>
          {/*<DrawerCloseButton/>*/}
          <DrawerHeader>
            <Flex wrap={'nowrap'} style={{ justifyContent: 'space-between' }}>
              <Link onClick={onClose} to="/">
                <Heading
                  cursor="pointer"
                  fontWeight="bold"
                  as="h1"
                  fontSize="36px"
                  letterSpacing={'-.1rem'}
                >
                  <Image
                    loading="lazy"
                    w="150px"
                    className="main-logo"
                    src={logo}
                  ></Image>
                </Heading>
              </Link>
              <Box cursor="pointer" fontSize="24px" onClick={toggleColorMode}>
                {' '}
                {colorMode === 'light' ? <FaMoon></FaMoon> : <FaSun></FaSun>}
              </Box>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {/** NOTE ITALIAN BOOK */}
            {/* <Text
              fontFamily="diodrum-bold !important"
              mt={4}
              ml={8}
              display="block"
              fontWeight="bold"
              fontSize="lg"
            >
              <Link to="/italian/shopbooks" onClick={onClose}>
                كشاف الكتب الايطالية
              </Link>
            </Text> */}
            <Text
              fontFamily="diodrum-bold !important"
              mt={4}
              ml={8}
              display="block"
              fontWeight="bold"
              fontSize="lg"
            >
              <Link onClick={onClose} to="/shopbooks">
                كشاف الكتب
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              mt={4}
              ml={8}
              display="block"
              fontWeight="bold"
              fontSize="lg"
            >
              <Link onClick={onClose} to="/shipping">
                الشحن والتوفير
              </Link>
            </Text>

            <Accordion allowToggle>
              <AccordionItem>
                <AccordionButton>
                  <Text
                    fontFamily="diodrum-bold !important"
                    mt={4}
                    ml={2}
                    display="block"
                    fontWeight="bold"
                    fontSize="lg"
                  >
                    كتب وقوائم مختارة
                  </Text>

                  <AccordionIcon mb="-25px" fontSize="25px" />
                </AccordionButton>
                <AccordionPanel p={4}>
                  <Stack
                    mt={0}
                    pr={3}
                    borderRight={1}
                    borderStyle={'solid'}
                    align={'start'}
                  >
                    <Link to="/writers" onClick={onClose}>
                      <Box
                        p={2}
                        _focus={{ bg: 'white', color: 'black' }}
                        _hover={{ bg: 'white', color: 'black' }}
                        fontSize="xl"
                        //w="100vw"
                        display="flex"
                      >
                        <Text fontSize="xl">كُتّاب اختاروا لك</Text>
                      </Box>
                    </Link>
                    <Link
                      onClick={onClose}
                      to="/25books?mutab_featurd=25before"
                    >
                      <Box
                        p={2}
                        _focus={{ bg: 'white', color: 'black' }}
                        _hover={{ bg: 'white', color: 'black' }}
                        fontSize="xl"
                        //w="100vw"
                        display="flex"
                      >
                        <Text fontSize="xl">كتب يجب أن تقرأها قبل أن تموت</Text>
                      </Box>
                    </Link>

                    <Link
                      _focus={{ bg: 'white', color: 'black' }}
                      _hover={{ bg: 'white', color: 'black' }}
                      fontSize="xl"
                      onClick={onClose}
                      to="/25books?mutab_featurd=25start"
                    >
                      <Box
                        p={2}
                        _focus={{ bg: 'white', color: 'black' }}
                        _hover={{ bg: 'white', color: 'black' }}
                        fontSize="xl"
                        // w="100vw"
                        display="flex"
                      >
                        <Text fontSize="xl">كتب لتبدأ بها القراءة</Text>
                      </Box>
                    </Link>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Text
              fontFamily="diodrum-bold !important"
              mt={4}
              ml={8}
              display="block"
              fontWeight="bold"
              fontSize="lg"
            >
              <Link onClick={onClose} to="/inBooksLove">
                في حب القراءة
              </Link>
            </Text>
            <Text
              fontFamily="diodrum-bold !important"
              mt={4}
              ml={8}
              display="block"
              fontWeight="bold"
              fontSize="lg"
            >
              <Link onClick={onClose} to="/events">
                نشاطات المكتبة
              </Link>
            </Text>
          </DrawerBody>
          <DrawerFooter
            style={{ justifyContent: 'start', marginBottom: '60px' }}
          >
            {auth && !auth.data && (
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton>
                    <Text
                      fontFamily="diodrum-bold !important"
                      // mt={{ base: 4, md: 0 }}
                      ml={2}
                      display="block"
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      الحساب
                    </Text>

                    <AccordionIcon mb="-10px" fontSize="25px" />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Stack
                      mt={0}
                      pr={3}
                      borderRight={1}
                      borderStyle={'solid'}
                      align={'start'}
                    >
                      <Link onClick={onClose} to="/login">
                        <Box
                          p={2}
                          _focus={{ bg: 'white', color: 'black' }}
                          _hover={{ bg: 'white', color: 'black' }}
                          fontSize="xl"
                          //w="100vw"
                          display="flex"
                        >
                          <Text fontSize="xl">تسجيل الدخول</Text>
                        </Box>
                      </Link>

                      <Link
                        _focus={{ bg: 'white', color: 'black' }}
                        _hover={{ bg: 'white', color: 'black' }}
                        fontSize="xl"
                        onClick={onClose}
                        to="/register"
                      >
                        <Box
                          p={2}
                          _focus={{ bg: 'white', color: 'black' }}
                          _hover={{ bg: 'white', color: 'black' }}
                          fontSize="xl"
                          //w="100vw"
                          display="flex"
                        >
                          <Text fontSize="xl">تسجيل جديد</Text>
                        </Box>
                      </Link>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}

            {auth.data && (
              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton>
                    <Text
                      fontFamily="diodrum-bold !important"
                      mt={{ base: 4, md: 0 }}
                      ml={2}
                      display="block"
                      fontWeight="bold"
                      fontSize="lg"
                    >
                      الحساب
                    </Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Stack
                      mt={0}
                      pr={3}
                      borderRight={1}
                      borderStyle={'solid'}
                      align={'start'}
                    >
                      <Link onClick={onClose} to="/profile">
                        <Box
                          p={2}
                          _focus={{ bg: 'white', color: 'black' }}
                          _hover={{ bg: 'white', color: 'black' }}
                          fontSize="xl"
                          //w="100vw"
                          display="flex"
                        >
                          <Text fontSize="xl">حسابي</Text>
                        </Box>
                      </Link>

                      <Link fontSize="xl" onClick={onClose} to="/orders">
                        <Box
                          p={2}
                          _focus={{ bg: 'white', color: 'black' }}
                          _hover={{ bg: 'white', color: 'black' }}
                          fontSize="xl"
                          //w="100vw"
                          display="flex"
                        >
                          <Text fontSize="xl">مشترياتي</Text>
                        </Box>
                      </Link>

                      <Link
                        fontSize="xl"
                        onClick={onClose}
                        onClick={() => {
                          logout();
                          // window.location.replace('/');
                        }}
                      >
                        <Box
                          p={2}
                          _focus={{ bg: 'white', color: 'black' }}
                          _hover={{ bg: 'white', color: 'black' }}
                          fontSize="xl"
                          //w="100vw"
                          display="flex"
                        >
                          <Text fontSize="xl">تسجيل الخروج</Text>
                        </Box>
                      </Link>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

const mapStateToProps = state => {
  return { auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return { logout: () => dispatch(logout()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
