import React, { useState, useEffect } from 'react';

import {
  Box,
  Flex,
  Text,
  useColorMode,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  SimpleGrid,
  Spinner,
  useMediaQuery,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  DrawerHeader,
  Center,
  useBreakpointValue,
} from '@chakra-ui/core';
import { NavLink, Link } from 'react-router-dom';
import { FaChevronDown, FaTimes } from 'react-icons/fa';

import { connect } from 'react-redux';
// import { getSeries } from '../../redux/actions/seriesActions';
import { getShopBooks } from '../../redux/actions/shopBooksActions';
import { GiHamburgerMenu } from 'react-icons/gi';

function Navbar({ getShopBooks }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isLargerThan1280] = useMediaQuery('(max-width: 420px)');
  const [isLargerThan790] = useMediaQuery('(min-width: 790px)');
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const filter = { light: '#000000', dark: '#1a202c' };

  const hoverBg = { light: '#000', dark: 'white' };
  const hoverClr = { light: 'white', dark: '#000' };

  useEffect(() => {
    async function getData() {
      const res = await getShopBooks({ page: 1, translate: null });
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [getShopBooks]);

  const handleToggle = () => setShow(!show);

  const onOpened = () => {
    document.body.style.overflow = 'hidden';
  };

  const onClosed = () => {
    document.body.style.overflow = '';
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      borderBottom="1px solid #ddd"
      as="nav"
      align="center"
      bg={bg[colorMode]}
      overflowX={{ base: 'auto', sm: 'auto' }}
      wrap={['nowrap', 'nowrap', 'wrap', 'wrap']}
      direction={['row', 'row', 'row', 'row']}
    >
      {!isLargerThan790 && (
        <IconButton
          mr="1em"
          onClick={onOpen}
          icon={<GiHamburgerMenu size="25px" />}
          bg="transparent"
          _hover={{ bg: 'transparent' }}
          _focus={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        />
      )}
      {isLargerThan790 && (
        <Flex px={['0', '5%']}>
          <NavLink
            activeStyle={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
              height: '100%',
              marginLeft: 5,
            }}
            to="/shopbooks"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                جميعها
              </Text>
            </Flex>
          </NavLink>
          <NavLink
            activeStyle={{
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: 'black',
              height: '100%',
              marginLeft: 5,
            }}
            to="/mediumbooks"
          >
            <Flex
              h="100%"
              direction="column"
              justifyContent="center"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
            >
              <Text
                whiteSpace="nowrap"
                fontWeight="bold"
                p="4"
                fontSize={['lg', '2xl']}
                fontFamily="diodrum-med !important"
              >
                منشورات المتوسط
              </Text>
            </Flex>
          </NavLink>
          <Menu onOpen={onOpened} onClose={onClosed}>
            <MenuButton
              display="block"
              // px={4}
              // py={2}
              whiteSpace="nowrap"
              as={Text}
              //   mt={{ base: 4, md: 0 }}
              p="4"
              fontSize={['lg', '2xl']}
              fontWeight="bold"
              transition="all 0.2s"
              // _hover={{ bg: 'gray.100' }}
              // _focus={{ outline: 0, boxShadow: 'outline' }}
              fontFamily="diodrum-med !important"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              cursor="pointer"
            >
              دور نشر أخرى
              <FaChevronDown
                style={{
                  display: 'inline',
                  fontSize: 15,
                  marginRight: 3,
                  marginTop: 5,
                }}
              ></FaChevronDown>{' '}
            </MenuButton>
            <MenuList
              maxHeight="60vh"
              w="100vw"
              color="white"
              overflowY={{ base: 'scroll', sm: 'scroll' }}
              bg={filter[colorMode]}
              shadow="xl"
            >
              <SimpleGrid
                pl={['5%', '5%', '15%', '15%']}
                pr={['5%', '5%', '15%', '15%']}
                columns={[1, 2, 3, 3, 4]}
                py={['5%', '0%', '0%', '0%']}
              >
                {data &&
                  data.publishers.map(pub => {
                    if (isLargerThan1280) {
                      return (
                        <Center>
                          <Link
                            style={{ margin: '50px !important' }}
                            onClick={handleToggle}
                            to={`/bypublisher?id=${pub.id} `}
                            key={pub.id}
                          >
                            <Box m={['0.9', '4']}>
                              <MenuItem
                                _focus={{ bg: 'white', color: 'black' }}
                                _hover={{ bg: 'white', color: 'black' }}
                                fontSize="sm"
                              >
                                <Box textAlign="right">
                                  <Heading
                                    dir="rtl"
                                    fontFamily="diodrum-med !important"
                                    fontSize={['lg', 'lg', 'xl', 'xl']}
                                  >
                                    {pub.name}
                                  </Heading>
                                </Box>
                              </MenuItem>
                            </Box>
                          </Link>
                        </Center>
                      );
                    } else {
                      return (
                        <Link
                          style={{ margin: '50px !important' }}
                          onClick={handleToggle}
                          to={`/bypublisher?id=${pub.id} `}
                          key={pub.id}
                        >
                          <Box m="4">
                            <MenuItem
                              _focus={{ bg: 'white', color: 'black' }}
                              _hover={{ bg: 'white', color: 'black' }}
                              fontSize="xl"
                            >
                              <Box textAlign="right">
                                <Heading
                                  dir="rtl"
                                  fontFamily="diodrum-med !important"
                                  fontSize={['lg', 'lg', 'xl', 'xl']}
                                >
                                  {pub.name}
                                </Heading>
                              </Box>
                            </MenuItem>
                          </Box>
                        </Link>
                      );
                    }
                  })}
              </SimpleGrid>
            </MenuList>
          </Menu>
          <Menu onOpen={onOpened} onClose={onClosed}>
            <MenuButton
              display="block"
              // px={4}
              // py={2}
              whiteSpace="nowrap"
              as={Text}
              //   mt={{ base: 4, md: 0 }}
              p="4"
              fontSize={['lg', '2xl']}
              fontWeight="bold"
              transition="all 0.2s"
              // _hover={{ bg: 'gray.100' }}
              // _focus={{ outline: 0, boxShadow: 'outline' }}
              fontFamily="diodrum-med !important"
              _hover={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _focus={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              _active={{
                bg: hoverBg[colorMode],
                color: hoverClr[colorMode],
              }}
              cursor="pointer"
            >
              حسب الصنف
              <FaChevronDown
                style={{
                  display: 'inline',
                  fontSize: 15,
                  marginRight: 3,
                  marginTop: 5,
                }}
              ></FaChevronDown>{' '}
            </MenuButton>
            <MenuList
              h="60vh"
              w="100vw"
              color="white"
              overflowY={{ base: 'scroll', sm: 'scroll' }}
              bg={filter[colorMode]}
            >
              {!data && (
                <Box w="100" mt="50" mb="50" textAlign="center">
                  <Spinner size="xl" />
                </Box>
              )}
              <SimpleGrid
                pl={['5%', '5%', '15%', '15%']}
                pr={['5%', '5%', '15%', '15%']}
                py={['5%', '0%', '0%', '0%']}
                columns={[1, 2, 3, 3, 4]}
              >
                {data &&
                  data.bookCategories.map(cat => {
                    if (isLargerThan1280) {
                      return (
                        <Center>
                          <Link
                            style={{ margin: '50px !important' }}
                            onClick={handleToggle}
                            to={`/bycategory?category=${cat.key} `}
                            key={cat.id}
                          >
                            <Box m={['0.9', '2']}>
                              <MenuItem
                                _focus={{ bg: 'white', color: 'black' }}
                                _hover={{ bg: 'white', color: 'black' }}
                                fontSize="xl"
                              >
                                <Box textAlign="right" display="flex">
                                  <Heading
                                    dir="rtl"
                                    fontFamily="diodrum-med !important"
                                    fontSize={['lg', 'lg', 'xl', 'xl']}
                                  >
                                    {cat.name}
                                  </Heading>
                                </Box>
                              </MenuItem>
                            </Box>
                          </Link>
                        </Center>
                      );
                    } else {
                      return (
                        <Link
                          style={{ margin: '50px !important' }}
                          onClick={handleToggle}
                          to={`/bycategory?category=${cat.key} `}
                          key={cat.id}
                        >
                          <Box m="2">
                            <MenuItem
                              _focus={{ bg: 'white', color: 'black' }}
                              _hover={{ bg: 'white', color: 'black' }}
                              fontSize="xl"
                            >
                              <Box textAlign="right" display="flex">
                                <Heading
                                  dir="rtl"
                                  fontFamily="diodrum-med !important"
                                  fontSize={['lg', 'lg', '2xl', 'xl']}
                                >
                                  {cat.name}
                                </Heading>
                              </Box>
                            </MenuItem>
                          </Box>
                        </Link>
                      );
                    }
                  })}
              </SimpleGrid>
            </MenuList>
          </Menu>
        </Flex>
      )}

      {/** // REVIEW Drawer */}
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="xs">
        <DrawerOverlay />
        <DrawerContent
          bg={colorMode === 'dark' ? bg[colorMode] : '#000'}
          color="white"
        >
          <DrawerHeader p="0">
            <IconButton
              p={8}
              onClick={onClose}
              icon={<FaTimes size="25px" />}
              bg="transparent"
              _hover={{ bg: 'transparent' }}
              _focus={{ bg: 'transparent' }}
              _active={{ bg: 'transparent' }}
            />
          </DrawerHeader>
          <DrawerBody p="0">
            <Flex direction="column">
              <NavLink
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black',
                  height: '100%',
                  marginLeft: 5,
                }}
                to="/shopbooks"
              >
                <Flex
                  h="100%"
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    جميعها
                  </Text>
                </Flex>
              </NavLink>
              <NavLink
                activeStyle={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: 'black',
                  height: '100%',
                  marginLeft: 5,
                }}
                to="/mediumbooks"
              >
                <Flex
                  h="100%"
                  direction="column"
                  justifyContent="center"
                  pt=".5em"
                  pb="1em"
                  px="1em"
                  _hover={{ bg: 'white', color: '#000' }}
                  _focus={{ bg: 'white', color: '#000' }}
                  _active={{ bg: 'white', color: '#000' }}
                >
                  <Text
                    whiteSpace="nowrap"
                    fontWeight="bold"
                    ml="4"
                    mr="4"
                    fontSize={['lg', '2xl']}
                    fontFamily="diodrum-med !important"
                  >
                    منشورات المتوسط
                  </Text>
                </Flex>
              </NavLink>
              <Accordion allowToggle>
                <AccordionItem border="none">
                  <AccordionButton
                    pt=".5em"
                    pb="1em"
                    px="2em"
                    _hover={{ bg: 'white', color: '#000' }}
                    _focus={{ bg: 'white', color: '#000' }}
                    __active={{ bg: 'transparent' }}
                  >
                    <Text
                      whiteSpace="nowrap"
                      fontWeight="bold"
                      fontSize={['lg', '2xl']}
                      fontFamily="diodrum-med !important"
                    >
                      دور نشر أخرى
                    </Text>
                    <AccordionIcon fontSize="25px" />
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    pt="0"
                    px="0"
                    borderRight="1px solid"
                    borderColor="gray.400"
                  >
                    {data &&
                      data.publishers.map(pub => {
                        return (
                          <Link
                            style={{ margin: '50px !important' }}
                            onClick={handleToggle}
                            to={`/bypublisher?id=${pub.id} `}
                            key={pub.id}
                          >
                            <Box
                              pt=".4em"
                              pb=".8em"
                              px="1em"
                              _hover={{ bg: 'white', color: '#000' }}
                              _focus={{ bg: 'white', color: '#000' }}
                              _active={{ bg: 'white', color: '#000' }}
                            >
                              <Box textAlign="right">
                                <Heading
                                  dir="rtl"
                                  fontFamily="diodrum-med !important"
                                  fontSize={['lg', 'lg', 'xl', 'xl']}
                                >
                                  {pub.name}
                                </Heading>
                              </Box>
                            </Box>
                          </Link>
                        );
                      })}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none">
                  <AccordionButton
                    pt=".5em"
                    pb="1em"
                    px="2em"
                    _hover={{ bg: 'white', color: '#000' }}
                    _focus={{ bg: 'white', color: '#000' }}
                    _active={{ bg: 'white', color: '#000' }}
                  >
                    <Text
                      whiteSpace="nowrap"
                      fontWeight="bold"
                      fontSize={['lg', '2xl']}
                      fontFamily="diodrum-med !important"
                    >
                      حسب الصنف
                    </Text>
                    <AccordionIcon fontSize="25px" />
                  </AccordionButton>
                  <AccordionPanel
                    pb={4}
                    pt="0"
                    px="0"
                    borderRight="1px solid"
                    borderColor="gray.400"
                  >
                    {!data && (
                      <Box w="100" mt="50" mb="50" textAlign="center">
                        <Spinner size="xl" />
                      </Box>
                    )}
                    {data &&
                      data.bookCategories.map(cat => {
                        return (
                          <Link
                            style={{ margin: '50px !important' }}
                            onClick={handleToggle}
                            to={`/bycategory?category=${cat.key} `}
                            key={cat.id}
                          >
                            <Box
                              pt=".4em"
                              pb=".8em"
                              px="1em"
                              _hover={{ bg: 'white', color: '#000' }}
                              _focus={{ bg: 'white', color: '#000' }}
                              _active={{ bg: 'white', color: '#000' }}
                            >
                              <Box textAlign="right" display="flex">
                                <Heading
                                  dir="rtl"
                                  fontFamily="diodrum-med !important"
                                  fontSize={['lg', 'lg', '2xl', 'xl']}
                                >
                                  {cat.name}
                                </Heading>
                              </Box>
                            </Box>
                          </Link>
                        );
                      })}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getShopBooks: payload => dispatch(getShopBooks(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Navbar);
