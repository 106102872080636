import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/core';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function blog() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title> نقاط التسليم PuntoDiRitiro</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
        نقاط التسليم PuntoDiRitiro{' '}
      </Heading>

      <Box
        textAlign="right"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Heading size="lg">
          تقع مكتبة المتوسط (موتاب Mutab) على العنوان التالي:
        </Heading>
        <Text fontSize="2xl" m="4">
          Via Lodovico Montegani, 33 / 20141 Milano MI / Italy
        </Text>
        <Text color="blue.500" fontSize="2xl" m="4">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://goo.gl/maps/SUePM4KSF8tCVQV6A"
          >
            على الخريطة
          </a>
        </Text>
        <Text fontSize="2xl" m="4">
          إذا كنتم تعيشون في ميلانو أو زائرين لها أو مارّين بها، يمكنكم دائماً
          القدوم لزيارة المكتبة هناك. بالطبع لا داعي للشراء، لكن نذكركم بمتعة
          تصفح الكتب ومعاينتها ومتعة التواجد في المكتبة دائماً وتبادل الآراء مع
          الكتبي حولها.
        </Text>
        <Text fontSize="2xl" m="4">
          إذا كنتم على عجلة من أمركم لاستلام كتبكم يمكنكم دائما تفعيل خيار
          (أستلم بنفسي) أثناء الشراء، ثم تحضرون لاستلام الكتب من المكتبة.
        </Text>
      </Box>
    </Box>
  );
}
