import React from 'react';
import { Box, Text, Heading } from '@chakra-ui/core';
// import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function blog() {
  return (
    <Box mt="100px" mb="100px">
      <Helmet>
        <title>عن موتاب Mutab</title>
      </Helmet>
      <Heading
        mr="4"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
        size="xl"
        fontFamily="diodrum-bold !important"
      >
        عن موتاب Mutab
        {/* <Divider
          mt="4"
          opacity="1"
          border="3px solid black"
          pl="20%"
          pr="20%"
          //   m="4"
          borderColor="black"
          w="20%"
        ></Divider> */}
      </Heading>

      <Box
        textAlign="right"
        p="30px"
        pl={['5%', '5%', '20%', '20%']}
        pr={['5%', '5%', '20%', '20%']}
      >
        <Text fontSize="2xl" m="4">
          موتاب هو متجر إلكتروني تابع لمنشورات المتوسط وقد أُنشِأ لبيع كتب الدار
          إضافة إلى كتب دور نشر أخرى، بقصد توفير الكتب العربية للقارئ العربي
          وغير العربي (طلبة اللغة العربية من غير الناطقين باللغة العربية) في
          أوروبا على وجه الخصوص وفي العالم بشكل عام. كما أن هذا المتجر يدعم عمل
          مكتبة المتوسط الواقعة في مدينة ميلانو الإيطالية بوصفها مكتبة العالم
          العربي في إيطاليا، بحيث نروِّج فيها ونوزِّع إضافة إلى الكتب العربية،
          الكتبَ المترجمة إلى اللغة الإيطالية عن العربية، أو الكتب المكتوبة
          بالإيطالية ولكنها تتناول العالم العربي.
        </Text>
        <Text fontSize="2xl" m="4">
          للتعرف على منشورات المتوسط تفضلوا بالنقر هنا.
        </Text>
      </Box>
    </Box>
  );
}
