import React from 'react';
import { Box, Flex, Link, useColorMode } from '@chakra-ui/core';
import {FaFacebook, FaInstagram, FaTwitter} from 'react-icons/fa';
import { IoLogoWhatsapp } from 'react-icons/io';


export default function SocialButtons() {
  const { colorMode, toggleColorMode } = useColorMode();

  const bg = { light: '#fff', dark: '#1a202c' };
  const bgIcon = { light: '#000', dark: '#fff' };
  const color = { light: 'white', dark: 'black' };
  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      style={{ position: 'fixed', zIndex: '99', top: '25%', right: 0 }}
    >
      <Flex direction="column">
        <Link
          //   className="social-href "
            href="https://www.facebook.com/Mutab-%D9%85%D9%88%D8%AA%D8%A7%D8%A8-100298032209373/"
          style={{ backgroundColor: bgIcon[colorMode] }}
            isExternal
        >
          <Box
            className="facebook"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaFacebook></FaFacebook>
          </Box>
        </Link>
        <Link
          //   className="social-href"
            href="https://twitter.com/Mutab77417321"
          style={{ backgroundColor: bgIcon[colorMode] }}
          isExternal
        >
          <Box
            className="twitter"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaTwitter></FaTwitter>
          </Box>
        </Link>

        <Link
          //   className="social-href"
          href=" https://wa.me/393477104442"
          style={{ backgroundColor: bgIcon[colorMode] }}
          isExternal
        >
          <Box
            className="whatsapp"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <IoLogoWhatsapp></IoLogoWhatsapp>
          </Box>
        </Link>

        <Link
            href="https://www.instagram.com/mutab.it/"
          //   className="social-href"
          style={{ backgroundColor: bgIcon[colorMode] }}
            isExternal
        >
          <Box
            className="whatsapp"
            bg={bgIcon[colorMode]}
            color={color[colorMode]}
            p="15px"
            fontSize="18px"
          >
            <FaInstagram></FaInstagram>
          </Box>
        </Link>
      </Flex>
    </Box>
  );
}
