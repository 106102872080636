import React from 'react';
import { Box, Alert } from '@chakra-ui/core';

import Resend from './Resend';

export default function NotVerified({ message }) {
  return (
    <Box
      fontFamily="diodrum-med !important"
      pr="10%"
      pl="10%"
      textAlign="center"
      mb="500px"
    >
      <Alert status="warning">{message}</Alert>
      <Resend></Resend>
    </Box>
  );
}
