import React, { useState, useEffect } from 'react';
import {
  Box,
  Spinner,
  Flex,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/core';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation, Link } from 'react-router-dom';

import { getMediumBooks } from '../../redux/actions/shopBooksActions';
import Filter from '../../components/shopBooks/filter';
import ShopBooks from '../../components/shopBooks/books';
import { Helmet } from 'react-helmet';
import usePersistPagination from '../../hooks/usePersistPagination';
import Headroom from 'react-headroom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Books({ getMediumBooks }) {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = usePersistPagination();

  const activeLink = {
    background: '#000',
    color: 'white',
  };

  let query = useQuery();
  let translate = query.get('translate');

  useEffect(() => {
    async function getData() {
      const res = await getMediumBooks({ page, translate });
      console.log(res);
      if (res) {
        setData(res.data);
      }
    }
    getData();
  }, [page, translate, getMediumBooks]);

  const handlePageClick = data => {
    setPage(data.selected + 1);
    setCurrentPage(data.selected);
    scroll.scrollToTop();
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'} mb="100px">
      <Helmet>
        <title> كشاف الكتب</title>
      </Helmet>
      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      {data && (
        <>
          {isSmallScreen && (
            <Headroom
              className="book-filter"
              style={{
                top: '70px',
                left: '0px',
                right: '0px',
                zIndex: '1',
                transform: 'translate3D(0px, 0px, 0px)',
                transition: 'all .5s ease-in-out 0s',
              }}
            >
              <Filter
                categories={data.bookCategories}
                publishers={data.publishers}
              />
            </Headroom>
          )}
          {!isSmallScreen && (
            <Filter
              categories={data.bookCategories}
              publishers={data.publishers}
            />
          )}

          <Box m={['0', '5%']}>
            <Box d="flex" m="4">
              <Link to={`/mediumbooks?translate=0`}>
                <Heading
                  fontFamily="diodrum-med !important"
                  fontWeight="normal"
                  size="md"
                  pt="2"
                  px="4"
                  pb="4"
                  style={translate === '0' ? activeLink : {}}
                >
                  عربي
                </Heading>
              </Link>
              <Link to={`/mediumbooks?&translate=1`}>
                <Heading
                  fontFamily="diodrum-med !important"
                  fontWeight="normal"
                  size="md"
                  pt="2"
                  px="4"
                  pb="4"
                  style={translate === '1' ? activeLink : {}}
                >
                  مترجم
                </Heading>
              </Link>
            </Box>
            <ShopBooks books={data.data} to="book/"></ShopBooks>
          </Box>

          <Flex>
            <ReactPaginate
              previousLabel={'السابق'}
              nextLabel={'التالى'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={data.last_page}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
              forcePage={currentPage}
            />
          </Flex>
        </>
      )}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getMediumBooks: payload => dispatch(getMediumBooks(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Books);
