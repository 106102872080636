import axios from 'axios';

export const validateAdress = payload => async (dispatch, getState) => {
  console.log(payload);
  //   const { phone, country, city, address, address_2 } = profile;
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .post(`${process.env.REACT_APP_API}/validateAddress`, payload, { headers })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(err => {
      console.log(err);
    });
  return data;
};
export const shippingRates = payload => async (dispatch, getState) => {
  console.log(payload);
  //   const { phone, country, city, address, address_2 } = profile;
  const auth = getState().auth;
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ` + auth.data.access_token,
  };

  let data;
  data = await axios
    .post(`${process.env.REACT_APP_API}/shippingRates`, payload, { headers })
    .then(res => {
      console.log(res);
      //   if (res.data.paypal_link) {
      //     window.open(res.data.paypal_link, '_blank');
      //   }
      return res;
    })
    .catch(err => {
      console.log(err.response);
      return err.response;
    });
  return data;
};
