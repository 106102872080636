import React, { useState } from 'react';
import { Alert, Button, Text, useToast, Flex } from '@chakra-ui/core';

import { connect } from 'react-redux';
import { emailResend } from '../redux/actions/verifyActions';

function NotVerified({ emailResend }) {
  const toast = useToast();
  //   const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const handleResend = async () => {
    setLoading(true);
    const res = await emailResend();
    if (res?.status === 201) {
      setLoading(false);
      toast({
        title: res.data,
        //    description: "We've created your account for you.",
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      setLoading(false);
      toast({
        title: 'حدث خطأ ما أعد المحاولة',
        //    description: "We've created your account for you.",
        status: 'error',
        duration: 2000,
        isClosable: true,
        position: 'top-right',
      });
    }
    console.log(res);
  };
  return (
    <Alert mt="4">
      <Flex justifyContent="space-between">
        <Text>أعد ارسال الايميل</Text>
        <Button
          mr="8"
          colorScheme="blue"
          isDisabled={loading}
          isLoading={loading}
          onClick={handleResend}
        >
          اعادة الارسال
        </Button>
      </Flex>
    </Alert>
  );
}

const mapDispatchToProps = dispatch => {
  return { emailResend: () => dispatch(emailResend()) };
};

export default connect(null, mapDispatchToProps)(NotVerified);
