import React from 'react';
import Masonry from 'react-masonry-css';
import { Helmet } from 'react-helmet';

import {
  Box,
  Heading,
  Text,
  Image,
  Skeleton,
  Spinner,
  useColorMode,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getChosenAuthors } from '../redux/actions/authorActions';

function Writers({ getAuthors }) {
  const { colorMode } = useColorMode();

  const bg = { light: 'white', dark: '#151a23' };
  const [data, setData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const imageLoaded = () => {
    setLoaded(true);
  };
  React.useEffect(() => {
    async function getData() {
      const res = await getAuthors();
      console.log('AUTHORS', res);
      setData(res.data.authors);
    }
    getData();
  }, []);

  const breakpointColumnsObj = {
    default: 3,
    1300: 3,
    1100: 2,
    1000: 1,
  };

  return (
    <Box className="padding" mt={{ base: '2em', md: '8em' }} mb="100px">
      <Helmet>
        <title> كتاب المتوسط</title>
      </Helmet>
      <Heading fontFamily="diodrum-bold !important" size="xl" mb="1em">
        كتاب المتوسط
      </Heading>
      {!data && (
        <Box textAlign="center">
          <Spinner size="xl" />
        </Box>
      )}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {data &&
          Object.values(data).map(author => (
            <Link key={author.id} to={`/writer/${author.id}`}>
              <Box oveflow="hidden" pb="4" mt="4" mb="4" bg={bg[colorMode]}>
                <Skeleton w="100%" isLoaded={loaded}>
                  <Image
                    onLoad={imageLoaded}
                    w="100%"
                    //   h="150px"
                    // m="2"
                    src={`${process.env.REACT_APP_STORAGE}/${author.image}`}
                  ></Image>
                </Skeleton>
                <Text
                  _hover={{
                    bg: 'black',
                    color: 'white',
                    textDecoration: 'underline',
                  }}
                  mt="4"
                  mr="4"
                  fontSize="xl"
                  fontFamily="diodrum-med !important"
                >
                  {author.name}
                </Text>
              </Box>
            </Link>
          ))}
      </Masonry>
      {/* <Flex mt="8" flexWrap="wrap"></Flex> */}
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return { getAuthors: () => dispatch(getChosenAuthors()) };
};

export default connect(null, mapDispatchToProps)(Writers);
