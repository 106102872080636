import React from 'react';
import {
  Box,
  Stack,
  Input,
  Button,
  Flex,
  InputGroup,
  useToast,
  
  useColorMode,
} from '@chakra-ui/core';
import { Helmet } from 'react-helmet';


import { connect } from 'react-redux';
import { forgetPassword } from '../redux/actions/authAction';

function Register({ forgetPassword }) {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const bg = { light: 'white', dark: '#151a23' };

  const [loading, setLoading] = React.useState(false);
  const [register, setRegister] = React.useState({
    email: '',
    website: window.location.origin + '/login',
  });

  const handleChange = e => {
    setRegister(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    const response = await forgetPassword(register);
    console.log(response);
    setLoading(false);
    if (response.status === 404) {
      toast({
        title: 'حدث خطا',
        position: 'top-right',
        description: response.data.msg,
        status: 'error',
        duration: null,
        isClosable: true,
      });
    } else {
      toast({
        title: 'تمت العملية بنجاح',
        position: 'top-right',
        description: response.data.msg,
        status: 'success',

        duration: null,
        isClosable: true,
      });
    }
    // if (response && response.error) {
    //   toast({
    //     title: 'حدث خطا',
    //     position: 'top-right',
    //     description: response.error,
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   setLoading(false);
    // } else if (response && response.status === 200) {
    //   //? dispatch action to save the user and the token
    //   saveUser(response);
    //   setLoading(false);
    //   window.location.reload();
    //   console.log('you can dispatch');
    // }
  };

  console.log(register);

  return (
    <Box
      fontFamily="diodrum-med !important"
      mt="130px"
      mb="160px"
      textAlign="center"
    >
      <Helmet>
        <title>تغيير كلمة السر</title>
      </Helmet>
      <Box>
        <Flex justifyContent="center">
          <Stack p="8" bg={bg[colorMode]} w="400px">
            <InputGroup>
              <Input
                onChange={handleChange}
                className="auth-input"
                border="1px solid black"
                borderColor="black"
                type="email"
                name="email"
                placeholder="البريد الالكتروني"
              ></Input>
            </InputGroup>

            <Button
              isLoading={loading}
              isDisabled={register.email === ''}
              onClick={handleSubmit}
              bg="black"
              color="white"
            >
              ارسال
            </Button>
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    forgetPassword: email => dispatch(forgetPassword(email)),
  };
};

export default connect(null, mapDispatchToProps)(Register);
