import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  Box,
  Heading,
  Text,
  Image,
  Divider,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  List,
  ListItem,
  Flex,
  Skeleton,
  useColorMode,
  useToast,
  Spinner,
  AspectRatio,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  useBreakpointValue,
  Accordion,
} from '@chakra-ui/core';
import Carousel from 'react-elastic-carousel';
import { useParams, Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { getBook } from '../redux/actions/booksActions';
import { addBook, removeBook } from '../redux/actions/cartActions';

import GlobalShare from '../util/GlobalShare';
import Filter from '../components/shopBooks/filter';
import { FaShoppingBag, FaTrash } from 'react-icons/fa';
import Headroom from 'react-headroom';
function Book({ getBook, addBook, removeBook, auth, cart }) {
  const { colorMode } = useColorMode();
  const toast = useToast();
  let { id } = useParams();
  const breakPointMd = useBreakpointValue({ base: false, md: true });

  const bg = { light: '#f5f2ef', dark: '#1a202c' };
  const color = { light: 'black', dark: 'white' };

  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  let checkIfExist =
    cart &&
    data &&
    cart.books &&
    cart.books.map(item => {
      return item.book.id === data.id;
    });
  let exist = checkIfExist && checkIfExist.includes(true);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1900, itemsToShow: 4 },
  ];
  const bookBreakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 4 },
  ];

  useEffect(() => {
    async function getData() {
      const res = await getBook(id);
      if (res?.data) {
        setData(res.data);
        console.log('book data', res.data);
      } else {
        return (window.location.href = '/404');
      }
    }
    getData();
  }, [id]);

  const imageLoaded = () => {
    setLoaded(true);
  };

  //?handle boook submit
  const handleSubmit = () => {
    if (exist) {
      removeBook({ book: data, qnt: 1 });
      toast({
        //  title: 'Account created.',
        description: 'الكتاب حذف من سلة المشتريات ',
        position: 'top-right',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } else {
      addBook({ book: data, qnt: 1 });
      toast({
        //  title: 'Account created.',
        description: 'الكتاب اضيف الى سلة المشتريات بنجاح',
        position: 'top-right',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt={isSmallScreen ? '0' : '70px'} className="test">
      {data && (
        <>
          {isSmallScreen && (
            <Headroom
              className="book-filter"
              style={{
                top: '70px',
                left: '0px',
                right: '0px',
                zIndex: '1',
                transform: 'translate3D(0px, 0px, 0px)',
                transition: 'all .5s ease-in-out 0s',
              }}
            >
              <Filter
                categories={data.bookCategories}
                publishers={data.publishers}
              />
            </Headroom>
          )}
          {!isSmallScreen && (
            <Filter
              categories={data.bookCategories}
              publishers={data.publishers}
            />
          )}
        </>
      )}

      <Box mb="2em" mt={{ base: '2em', md: '5em' }}>
        {!data && (
          <Box textAlign="center">
            <Spinner size="xl" />
          </Box>
        )}
        {data && (
          <>
            <Helmet>
              <title>{data.title}</title>
              <meta name="title" content={data.title} />
              {/* <meta name="description" content={data.description} /> */}

              <meta property="og:title" content={data.title} />
              <meta
                property="og:url"
                content={`https://mutab.it/book/${data.id}`}
              />
              <meta property="og:type" content="book" />
              {/* <meta property="og:description" content={data.description} /> */}
              <meta
                property="og:image"
                content={`${process.env.REACT_APP_STORAGE}/${data.cover}`}
              />
            </Helmet>
            <Flex
              direction={['column', 'column', 'column', 'row']}
              px={['2%', '2%', '5%', '5%']}
            >
              <Box w={{ base: '100%', lg: '30%' }} maxW="400px" mx="auto">
                {/** BOOK IMAGE */}
                <Box>
                  <Skeleton isLoaded={loaded}>
                    <Image
                      laading="lazy"
                      onLoad={imageLoaded}
                      shadow="lg"
                      mx="auto"
                      src={`${process.env.REACT_APP_STORAGE}/${data.cover}`}
                    />
                  </Skeleton>
                </Box>
                {/** BOOK CTA */}
                <Flex direction="column" align="center" py="1.5em">
                  {data.available === 0 ? (
                    <Button
                      w="100%"
                      rounded="20px"
                      fontFamily="diodrum-med !important"
                    >
                      غير متاح حاليا
                    </Button>
                  ) : (
                    <Button
                      w="100%"
                      leftIcon={exist ? <FaTrash /> : <FaShoppingBag />}
                      onClick={handleSubmit}
                      rounded="20px"
                      colorScheme={exist ? 'red' : 'green'}
                      fontFamily="diodrum-med !important"
                    >
                      <Text mr={['1', '2']}>
                        {exist
                          ? 'حذف الكتاب من سلة المشتريات'
                          : 'اضف الكتاب الى سلة المشتريات'}
                      </Text>
                    </Button>
                  )}
                  {/** SOCIAL SHARE  */}
                  <Box mt="1em">
                    <GlobalShare />
                  </Box>
                  {/** BOOK PODCAST / VIDEO */}
                  <Box w={['300px', '300px', '400px', '400px']}>
                    {data.podcast && (
                      <Box>
                        <iframe
                          title={data.title}
                          width="100%"
                          height="100"
                          scrolling="no"
                          frameborder="no"
                          allow="autoplay"
                          src={data.podcast}
                        ></iframe>
                      </Box>
                    )}
                    {data.video && (
                      <AspectRatio ratio={1}>
                        <Box
                          fontSize="2xl"
                          className="content"
                          dangerouslySetInnerHTML={{ __html: data.video }}
                        ></Box>
                      </AspectRatio>
                    )}
                  </Box>
                </Flex>
              </Box>
              <Box
                w={{ base: '100%', lg: '70%' }}
                mx={{ base: '0', lg: '1.5em' }}
                p={{ base: '1em', lg: '0' }}
              >
                {/** BOOK DESCRIPTION */}
                {data.searchable_out_mutab === 0 ? (
                  <Box>
                    {/** WITHOUT TABS  */}
                    <Heading fontFamily="diodrum-med !important" mb="4">
                      {data.title}
                    </Heading>
                    <Divider />
                    <Text fontFamily="diodrum-med !important" fontSize="2xl">
                      {data.sub_title}
                    </Text>
                    <Flex>
                      <Text
                        _hover={{
                          bg: 'black',
                          color: 'white',
                        }}
                        m="2"
                        fontSize="2xl"
                        color="gray.500"
                      >
                        {data.mutab_author}
                      </Text>
                    </Flex>
                    <Divider />
                    {data.translate_from && (
                      <Text fontSize="2xl">
                        ترجم عن : {data.translate_from}
                      </Text>
                    )}
                    <Box
                      fontSize="2xl"
                      my="4"
                      className="content"
                      style={{ WebkitUserSelect: 'none' }}
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                    <Divider />
                    <List mt="2" fontSize="2xl">
                      <ListItem>الناشر : {data.publisher} </ListItem>
                      <ListItem>تاريخ النشر : {data.mutab_publish}</ListItem>
                      <ListItem>عدد الصفحات : {data.page_number} </ListItem>
                      <ListItem dir="ltr"> {data.isbn} : ISBN </ListItem>
                      <ListItem>السعر : {data.price}€</ListItem>
                    </List>
                  </Box>
                ) : (
                  <Box>
                    <Heading fontFamily="diodrum-med !important" mb="4">
                      {data.title}
                    </Heading>
                    <Divider />
                    <Text fontFamily="diodrum-med !important" fontSize="2xl">
                      {data.sub_title}
                    </Text>
                    {data.author.map(author => (
                      <Box key={author.id} d="inline-block" ml="1em">
                        <Link key={author.id} to={`/author/${author.id}`}>
                          <Text
                            _hover={{
                              bg: 'black',
                              color: 'white',
                              textDecoration: 'underline',
                            }}
                            mt="2"
                            fontSize="2xl"
                            color="gray.500"
                          >
                            {author.name}
                          </Text>
                        </Link>
                      </Box>
                    ))}
                    <Divider />
                    {data.translate_from && (
                      <Text mt="2" fontSize="2xl">
                        ترجم عن : {data.translate_from}
                      </Text>
                    )}
                    <Box
                      my="4"
                      fontSize="2xl"
                      className="content"
                      dangerouslySetInnerHTML={{ __html: data.overview }}
                    />
                    <Divider />
                    {/** // REVIEW Turn it into an accordion on mobile devices  */}
                    {breakPointMd && (
                      <Tabs>
                        <TabList className="booktablist">
                          {data.description && (
                            <Tab whiteSpace="nowrap" fontSize="18px">
                              عن الكتاب
                            </Tab>
                          )}
                          {data.author && (
                            <Tab whiteSpace="nowrap" fontSize="18px">
                              عن المؤلف
                            </Tab>
                          )}
                          {data.index && (
                            <Tab whiteSpace="nowrap" fontSize="18px">
                              فهرس الكتاب
                            </Tab>
                          )}
                          {data.from_book && (
                            <Tab whiteSpace="nowrap" fontSize="18px">
                              من الكتاب
                            </Tab>
                          )}
                          {data.press_external_link && (
                            <Tab whiteSpace="nowrap" fontSize="18px">
                              في الصحافة
                            </Tab>
                          )}

                          <Tab whiteSpace="nowrap" fontSize="18px">
                            معلومات الكتاب
                          </Tab>
                        </TabList>

                        <TabPanels>
                          {data.description && (
                            <TabPanel>
                              {/* <Text fontSize="xl" mt="4">
                        {data.description}
                      </Text> */}
                              <Box
                                fontSize="2xl"
                                className="content"
                                style={{ WebkitUserSelect: 'none' }}
                                dangerouslySetInnerHTML={{
                                  __html: data.description,
                                }}
                              />
                            </TabPanel>
                          )}
                          {data.author && (
                            <TabPanel>
                              {data.author.map(author => (
                                <Box key={author.id}>
                                  <Heading size="lg">{author.name}</Heading>
                                  <Box
                                      m={2}
                                      fontSize="2xl"
                                      className="content"
                                      dangerouslySetInnerHTML={{
                                        __html: author.author_bio,
                                      }}
                                  />
                                </Box>
                              ))}
                            </TabPanel>
                          )}
                          {data.index && (
                            <TabPanel fontSize="xl">
                              <Box
                                fontSize="xl"
                                className="content"
                                dangerouslySetInnerHTML={{ __html: data.index }}
                              />
                            </TabPanel>
                          )}
                          {data.from_book && (
                            <TabPanel fontSize="xl">
                              <Box
                                fontSize="xl"
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: data.from_book,
                                }}
                              />
                            </TabPanel>
                          )}
                          {data.press_external_link && (
                            <TabPanel>
                              <Box
                                fontSize="xl"
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: data.press_external_link,
                                }}
                              />
                            </TabPanel>
                          )}
                          <TabPanel fontSize="xl">
                            <List mt="4">
                              <ListItem>الناشر : {data.publisher} </ListItem>
                              <ListItem>
                                تاريخ النشر :{' '}
                                {moment(data.publish_date).format('yyyy/MM/DD')}
                              </ListItem>
                              <ListItem>
                                عدد الصفحات : {data.page_number}{' '}
                              </ListItem>
                              <ListItem dir="ltr">
                                {' '}
                                {data.isbn} : ISBN{' '}
                              </ListItem>
                              <ListItem>السعر : {data.price}€</ListItem>
                              <ListItem>
                                هاشتاغ :{' '}
                                <Box
                                  d="inline"
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: data.hashtag,
                                  }}
                                />
                              </ListItem>
                            </List>
                          </TabPanel>
                        </TabPanels>
                      </Tabs>
                    )}
                    {!breakPointMd && (
                      <Accordion allowToggle allowMultiple={true}>
                        {data.description && (
                          <AccordionItem>
                            <AccordionButton
                              bg="#000"
                              color="white"
                              _hover={{ bg: '#000', color: 'white' }}
                            >
                              <Text whiteSpace="nowrap" fontSize="1.5em">
                                عن الكتاب
                              </Text>
                              <AccordionIcon mb="-5px" mr="5px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              <Box
                                fontSize="2xl"
                                className="content"
                                style={{ WebkitUserSelect: 'none' }}
                                dangerouslySetInnerHTML={{
                                  __html: data.description,
                                }}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        {data.author && (
                          <AccordionItem>
                            <AccordionButton
                              bg="#000"
                              color="white"
                              _hover={{ bg: '#000', color: 'white' }}
                            >
                              <Text whiteSpace="nowrap" fontSize="1.5em">
                                عن المؤلف
                              </Text>
                              <AccordionIcon mb="-5px" mr="5px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              {data.author.map(author => (
                                <Box key={author.id}>
                                  <Heading size="lg">{author.name}</Heading>
                                  <Box
                                      m={2}
                                      fontSize="2xl"
                                      className="content"
                                      dangerouslySetInnerHTML={{
                                        __html: author.author_bio,
                                      }}
                                  />
                                </Box>
                              ))}
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        {data.index && (
                          <AccordionItem>
                            <AccordionButton
                              bg="#000"
                              color="white"
                              _hover={{ bg: '#000', color: 'white' }}
                              n
                            >
                              <Text whiteSpace="nowrap" fontSize="1.5em">
                                فهرس الكتاب
                              </Text>
                              <AccordionIcon mb="-5px" mr="5px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              <Box
                                fontSize="xl"
                                className="content"
                                dangerouslySetInnerHTML={{ __html: data.index }}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        {data.from_book && (
                          <AccordionItem>
                            <AccordionButton
                              bg="#000"
                              color="white"
                              _hover={{ bg: '#000', color: 'white' }}
                            >
                              <Text whiteSpace="nowrap" fontSize="1.5em">
                                من الكتاب
                              </Text>
                              <AccordionIcon mb="-5px" mr="5px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              <Box
                                fontSize="xl"
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: data.from_book,
                                }}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        {data.press_external_link && (
                          <AccordionItem>
                            <AccordionButton
                              bg="#000"
                              color="white"
                              _hover={{ bg: '#000', color: 'white' }}
                            >
                              <Text whiteSpace="nowrap" fontSize="1.5em">
                                في الصحافة
                              </Text>
                              <AccordionIcon mb="-5px" mr="5px" />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                              <Box
                                fontSize="xl"
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html: data.press_external_link,
                                }}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                        <AccordionItem>
                          <AccordionButton
                            bg="#000"
                            color="white"
                            _hover={{ bg: '#000', color: 'white' }}
                          >
                            <Text whiteSpace="nowrap" fontSize="1.5em">
                              معلومات الكتاب
                            </Text>
                            <AccordionIcon mb="-5px" mr="5px" />
                          </AccordionButton>
                          <AccordionPanel fontSize="xl" pb={4}>
                            <List>
                              <ListItem>الناشر : {data.publisher} </ListItem>
                              <ListItem>
                                تاريخ النشر :{' '}
                                {moment(data.publish_date).format('yyyy/MM/DD')}
                              </ListItem>
                              <ListItem>
                                عدد الصفحات : {data.page_number}{' '}
                              </ListItem>
                              <ListItem dir="ltr">
                                {' '}
                                {data.isbn} : ISBN{' '}
                              </ListItem>
                              <ListItem>السعر : {data.price}€</ListItem>
                              <ListItem>
                                هاشتاغ :{' '}
                                <Box
                                  d="inline"
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: data.hashtag,
                                  }}
                                />
                              </ListItem>
                            </List>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    )}
                  </Box>
                )}
              </Box>
            </Flex>
          </>
        )}
      </Box>
      {/** RELATED BOOKS */}
      {data && data.books[0] && (
        <Box pr="7%" pl="3%" bg={bg[colorMode]} borderBottom="1px solid white">
          <Box mb="4" color={color[colorMode]}>
            <Heading
              fontFamily="diodrum-med !important"
              mx="7%"
              p="4"
              size="lg"
            >
              كتب ذات صلة
            </Heading>
          </Box>
          <Carousel
            breakPoints={bookBreakPoints}
            isRTL={true}
            style={{
              //   marginTop: 100,

              paddingBottom: 10,
            }}
            //   itemsToScroll={3}
            //   itemsToShow={3}
          >
            {data.books.map(book => (
              <a key={book.id} href={`/book/${book.id}`}>
                <Box mb="4" cursor="pointer">
                  <Image
                    loading="lazy"
                    w="225px"
                    h="350px"
                    m="0 auto"
                    shadow="lg"
                    src={`${process.env.REACT_APP_STORAGE}/${book.cover}`}
                  ></Image>
                  <Box mt="4" textAlign="center">
                    <Text
                      fontFamily="diodrum-med !important"
                      fontWeight="500"
                      fontSize="xl"
                    >
                      {book.title}
                    </Text>
                    <Text fontSize="md">{book.sub_title}</Text>
                    <Text fontSize="sm">{book.author}</Text>
                    <Text fontWeight="bold">€{book.price}</Text>
                  </Box>
                </Box>
              </a>
            ))}
          </Carousel>
        </Box>
      )}
      {data && data.articles[0] && (
        <Box
          pr="5%"
          pl="3%"
          bg={bg[colorMode]}
          color="black"
          borderBottom="1px solid white"
        >
          <Box mb="4" color={color[colorMode]}>
            <Heading
              fontFamily="diodrum-med !important"
              mr="7%"
              p="4"
              size="lg"
            >
              مقالات ذات صلة
            </Heading>
          </Box>
          <Carousel
            breakPoints={breakPoints}
            isRTL={true}
            style={{
              //   marginTop: 100,

              paddingBottom: 10,
            }}
            //   itemsToScroll={3}
            //   itemsToShow={3}
          >
            {data.articles.map(article => (
              <Link to={`/singlePost?id=${article.id}`} key={article.id}>
                <Box
                  bg="white"
                  maxW="300px"
                  shadow="lg"
                  pb="4"
                  m={{ base: '0', md: '4' }}
                  cursor="pointer"
                >
                  <Box>
                    <Skeleton w="100%" isLoaded={loaded}>
                      <Image
                        loading="lazy"
                        w="100%"
                        //   h="200px"
                        onLoad={imageLoaded}
                        src={`${process.env.REACT_APP_STORAGE}/${article.image}`}
                      ></Image>
                    </Skeleton>
                    <Text
                      m="2"
                      mt="4"
                      fontSize="lg"
                      fontFamily="diodrum-med !important"
                    >
                      {' '}
                      {article.author}{' '}
                    </Text>
                    <Heading
                      fontFamily="diodrum-med !important"
                      m="2"
                      lineHeight="1.5"
                      fontSize={{ base: 'md', md: 'lg' }}
                    >
                      {article.title}
                    </Heading>
                    <Box
                      m="4"
                      className="content related__content event-body__book"
                    >
                      <Box dangerouslySetInnerHTML={{ __html: article.body }} />
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))}
          </Carousel>
        </Box>
      )}
    </Box>
  );
}

const mapStateToProps = state => {
  return { cart: state.cart, auth: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    getBook: id => dispatch(getBook(id)),
    addBook: book => dispatch(addBook(book)),
    removeBook: book => dispatch(removeBook(book)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Book);
